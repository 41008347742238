import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import {
  createStore,
  combineReducers,
  applyMiddleware,
  CombinedState
} from 'redux';
import PacienteStore, { PacienteState } from './stores/paciente/paciente.store';
import SystemStore, { SystemState } from './stores/system/system.store';

export type AppState = CombinedState<{
  paciente: PacienteState;
  system: SystemState;
}>;
export default function configureStore() {
  const rootReducer = combineReducers<AppState>({
    paciente: PacienteStore,
    system: SystemStore
  });
  const middlewares =
    process.env.NODE_ENV === 'development'
      ? [thunkMiddleware, logger]
      : [thunkMiddleware];
  const store = createStore(rootReducer, applyMiddleware(...middlewares));
  return store;
}

export const store = configureStore();

// export type CtxType = typeof store;

// export const StoreContext = React.createContext<CtxType>(store);
