import React from 'react';
import './RouteAnimator.scss';
import { CSSTransition } from 'react-transition-group';

interface State {
  render: boolean;
}

interface Props {
  show: boolean;
  id: string;
}

export default class RouteAnimator extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      render: this.props.show
    };
  }
  render() {
    return this.state.render ? (
      <CSSTransition
        in={this.props.show}
        timeout={500}
        classNames='route-animate'
        onExited={() => this.setState({ render: false })}
        unmountOnExit>
        {this.props.children}
      </CSSTransition>
    ) : (
      ''
    );
  }

  static getDerivedStateFromProps(props: Props) {
    return props.show ? { render: props.show } : null;
  }
}
