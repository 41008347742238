import React from 'react';
import './ModalWarning.scss';
import Backdrop from '../../Backdrop/Backdrop';
import { connect } from 'react-redux';
import { AppState } from '../../../configureStore';
import {
  ModalWarning as WarningState,
  updateWarning
} from '../../../stores/system';
import Button from '../../Button/Button';
import SystemActionTypes from '../../../stores/system/system.action-types';

interface Props {
  warning: WarningState;
  clearWarning: () => any;
}

const ModalWarning: React.FC<Props> = (props) => (
  <Backdrop show={!!props.warning} zIndex={500}>
    <div className='modal-warning'>
      <h3>{props.warning?.title}</h3>
      <h6>{props.warning?.text}</h6>
      <Button
        func={() => {
          props.clearWarning();
        }}>
        OK
      </Button>
    </div>
  </Backdrop>
);
const mapStateToProps = ({ system }: AppState) => ({
  warning: system.warning
});

const mapDispatchToProps = (dispatch) => ({
  clearWarning: () => dispatch(updateWarning(SystemActionTypes.CLEAR_WARNING))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWarning);
