import PacienteActionTypes from './paciente.action-types';
import { Reducer } from '../../utils/reduxTypes';
import { removeTimezone } from '../../utils/date';
import { isDate } from 'util';
import { HttpAuthResponse } from '../../utils/httpTypes';

interface ConsultaBase {
  agendamento: number;
  data: Date | string;
  item: string;
  itemAbrev: string;
  medico: string;
  critica: string;
  especialidade: string;
}

export interface ConsultaParsed extends ConsultaBase {
  data: Date;
}

export interface ConsultaRaw extends ConsultaBase {
  data: string;
}

interface PacienteBase {
  nome: string;
  cpf: number;
  dataNascimento: string | Date;
  convenio: string;
  telefone: number;
  consulta: ConsultaRaw[] | ConsultaParsed[];
  senhaAtendimento: string;
  sala: string;
  authQr?: boolean;
  notFound?: boolean;
  biometricData?: HttpAuthResponse | HttpAuthResponse[];
}

export interface PacienteState extends PacienteBase {
  dataNascimento: Date;
  consulta: ConsultaParsed[];
  status: PacienteActionTypes;
  usarBiometria: boolean;
}

export interface PacienteData extends PacienteBase {
  dataNascimento: string;
  consulta: ConsultaRaw[];
  usarBiometria: boolean;
}

const isConsultaParsed = (
  obj: ConsultaParsed[] | ConsultaRaw[]
): obj is ConsultaParsed[] => obj && obj.length && isDate(obj[0].data);

const initialState: PacienteState = {
  convenio: null,
  consulta: null,
  cpf: null,
  dataNascimento: null,
  nome: null,
  senhaAtendimento: null,
  status: PacienteActionTypes.CLEAR,
  telefone: null,
  usarBiometria: false,
  notFound: false,
  authQr: false,
  sala: null
};

const reducer: Reducer<PacienteState, PacienteActionTypes> = (
  state = { ...initialState },
  action: { payload?: PacienteData; type: PacienteActionTypes }
): PacienteState => {
  switch (action.type) {
    case PacienteActionTypes.CLEAR:
      return {
        ...initialState,
        status: action.type
      };
    case PacienteActionTypes.FAILED:
      return {
        ...initialState,
        status: action.type
      };
    case PacienteActionTypes.LOADED:
      const consultasParsed = isConsultaParsed(action.payload?.consulta)
        ? action.payload?.consulta
        : action.payload?.consulta?.map((consulta) => {
            const consultaParsed: ConsultaParsed = {
              ...consulta,
              data: removeTimezone(new Date(consulta.data))
            };
            return consultaParsed;
          });
      return {
        ...action.payload,
        consulta: consultasParsed,
        dataNascimento: isDate(action.payload?.dataNascimento)
          ? action.payload?.dataNascimento
          : removeTimezone(new Date(action.payload?.dataNascimento)),
        status: action.type
      };
    case PacienteActionTypes.LOADING:
      return {
        ...initialState,
        status: action.type
      };
    case PacienteActionTypes.BIOMETRICS:
      return {
        ...state,
        biometricData: action.payload.biometricData
      };
    default:
      return state;
  }
};

export default reducer;
