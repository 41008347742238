import React from 'react';
import './ModalCartaoConvenio.scss';
import cartaoConvenio from '../../../assets/icons/cartao convenio blue.png';
import ModalSingleInput from '../ModalSingleInput/ModalSingleInput';
import { environment } from '../../../environment';
import { AppState } from '../../../configureStore';
import { connect } from 'react-redux';

interface Props {
  closeModal(): any;
  show: boolean;
  setAuthMode: () => any;
  consultorio: number;
}

const ModalCartaoConvenio: React.FC<Props> = (props) => {
  return (
    <ModalSingleInput
      redirect={props.setAuthMode}
      closeModal={props.closeModal}
      desc='Digite seu código abaixo'
      icon={cartaoConvenio}
      iconAlt='Cartao do convenio'
      type='number'
      maxlength={10}
      route={`${environment.proxy}/wsapichecking/services/filtro-agendamento?consultorio=${props.consultorio}&cartao`}
      show={props.show}
      title='Confirmação'
      validity={[
        (inputEl) => {
          if (!inputEl.value) {
            inputEl.setCustomValidity('Esse campo é obrigatório');
            inputEl.reportValidity();
            return false;
          }
          return true;
        }
      ]}
    />
  );
};

const mapStateToProps = ({ system }: AppState) => ({
  consultorio: system.consultorio
});

export default connect(mapStateToProps)(ModalCartaoConvenio);
