import React, {
  useMemo,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react';
import './Agendamentos.scss';
import PageContainer from '../../components/PageContainer/PageContainer';
import { connect } from 'react-redux';
import { AppState } from '../../configureStore';
import { PacienteState, updatePaciente } from '../../stores/paciente';
import Consulta from '../../components/Consulta/Consulta';
import Button from '../../components/Button/Button';
import thumbsup from '../../assets/icons/thumbsup.png';
import thumbsdown from '../../assets/icons/thumbsdown.png';
import PacienteActionTypes from '../../stores/paciente/paciente.action-types';
import { LinkContext } from '../../routes';
import {
  updateLoading,
  updateWarning,
  updateAtendimento
} from '../../stores/system/system.actions';
import SystemActionTypes from '../../stores/system/system.action-types';
import { LoadingState, ModalWarning } from '../../stores/system';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../environment';
import ConsultaConfirmada from '../../components/ConsultaConfirmada/ConsultaConfirmada';
import {
  SenhaHttp,
  CheckingAgendamentoHttp,
  CheckingAgendamentoHttpResponse
} from '../../utils/httpTypes';
import { isNumber } from 'util';
import { incrementCheckin } from '../../utils/localStorage';

interface Props {
  paciente: PacienteState;
  clearPaciente(): any;
  setLoadingStatus(status: LoadingState): any;
  updateWarning(warning: ModalWarning): any;
  clearSystem: () => any;
  consultorio: number;
  tipoAtendimento: number;
}

// const generateSenha = (): string => {
//   let senha = (((Math.random() * 1000) % 999) + 1).toFixed(0);
//   if (senha.length < 2) {
//     senha = '00' + senha;
//   } else if (senha.length < 3) {
//     senha = '0' + senha;
//   }
//   let letterCode = Math.random() * 100;
//   letterCode = letterCode - (letterCode % 1);
//   letterCode = letterCode % 26;
//   const letter = String.fromCharCode(letterCode + 65);
//   senha = letter + senha;
//   return senha;
// };

const Agendamentos: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);
  const requestDone = useRef(false);
  const [consultaSelected, setConsultaSelected] = useState<number>(null);
  const [consultaConfirmed, setConsultaConfirmed] = useState<number>(null);
  const [instaQueue] = useState<boolean>(
    props.paciente.status === PacienteActionTypes.CLEAR
  );
  const [senha, setSenha] = useState<number | string>(null);
  const [sala, setSala] = useState(null);
  const [pendencia, setPendencia] = useState(null);
  const setConsulta = (id: number) => {
    setConsultaSelected((prevState) => {
      if (prevState === id) {
        return null;
      }
      return id;
    });
  };
  const consultas = useMemo(
    () =>
      props.paciente.consulta?.map((consulta, index) => ({
        ...consulta,
        id: index
      })),
    [props.paciente.consulta]
  );
  const consultasHtml = consultas?.map((consulta, index) => (
    <Consulta
      key={index}
      consulta={consulta}
      setSelected={setConsulta}
      selected={consultaSelected === consulta.id}
    />
  ));

  const fetchSenha = useCallback(async () => {
    if (instaQueue && !senha) {
      props.setLoadingStatus(LoadingState.Loading);
      let response: AxiosResponse<SenhaHttp>;
      try {
        incrementCheckin();
        response = await axios.post(
          `${environment.proxy}/wsapichecking/services/gera-senha`,
          {
            // eslint-disable-next-line @typescript-eslint/camelcase
            consultorio_id: String(props.consultorio),
            // eslint-disable-next-line @typescript-eslint/camelcase
            prioridade_id: String(props.tipoAtendimento)
          },
          {
            headers: {
              Authorization: 'Basic YWRtaW46bWFzdGVy'
            }
          }
        );
      } catch (e) {
        props.updateWarning({
          text: 'Erro ao gerar senha. Entre em contato com o suporte',
          title: 'Erro',
          type: 'error'
        });
        props.setLoadingStatus(LoadingState.Failed);
      }
      props.setLoadingStatus(LoadingState.Success);
      setSenha(response?.data?.dados.senha);
    }
  }, [props, instaQueue, senha]);

  const confirmarConsulta = async (agendamento?: number) => {
    if (requestDone.current) {
      return;
    }
    if (!isNumber(agendamento)) {
      agendamento = null;
    }
    props.setLoadingStatus(LoadingState.Loading);
    const errorRoutine = () => {
      props.clearSystem();
      props.clearPaciente();
      context.Inicio.current.click();
    };
    const body: CheckingAgendamentoHttp = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      agendamento_id:
        agendamento || agendamento === 0
          ? String(agendamento)
          : String(
              consultas.find((consulta) => consulta.id === consultaSelected)
                ?.agendamento
            ),
      cartao: String(props.paciente.convenio),
      fila: String(props.tipoAtendimento)
    };
    let response: AxiosResponse<CheckingAgendamentoHttpResponse>;
    try {
      incrementCheckin();
      response = await axios.post(
        `${environment.proxy}/wsapichecking/services/checking-paciente`,
        body,
        {
          headers: {
            Authorization: 'Basic YWRtaW46bWFzdGVy'
          }
        }
      );

      // response = await new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({
      //       data: JSON.parse(`{
      //       "senha":"S796",
      //       "indicacao":null,
      //       "status":true
      //     }`)
      //     } as AxiosResponse);
      //   }, 2000);
      // });

      requestDone.current = true;
    } catch (e) {
      props.updateWarning({
        text: 'Erro ao confirmar agendamento. Entre em contato com o suporte',
        title: 'Erro',
        type: 'error'
      });
      props.setLoadingStatus(LoadingState.Failed);
      errorRoutine();
      return;
    }
    // if (!response.data.status) {
    //   props.updateWarning({
    //     text: response.data.motivo_critica
    //       ? response.data.motivo_critica
    //       : 'Erro ao confirmar agendamento. Entre em contato com o suporte.',
    //     title: 'Alerta',
    //     type: 'warning'
    //   });
    //   props.setLoadingStatus(LoadingState.Success);
    //   errorRoutine();
    //   return;
    // }
    props.setLoadingStatus(LoadingState.Success);
    setSenha(response.data.senha);
    setSala(response.data.indicacao);
    setPendencia(!!response.data.motivo_critica);
    setConsultaConfirmed(
      agendamento
        ? consultas.find((consulta) => consulta.agendamento === agendamento).id
        : consultaSelected
    );
  };

  if (instaQueue) {
    fetchSenha();
  }

  useEffect(() => {
    if (consultas?.length === 1 && !consultas[0].critica) {
      confirmarConsulta(consultas?.[0].agendamento);
      setConsultaSelected(consultas?.[0].id);
    }
  }, []);

  return consultaConfirmed || consultaConfirmed === 0 || instaQueue ? (
    <PageContainer title='Agendamento' breadcrumbState={3}>
      <div className='Agendamentos'>
        <h3>Confirmação do {instaQueue ? 'atendimento' : 'agendamento'}</h3>
        <h5>
          {instaQueue
            ? 'Em breve você será chamado por um de nossos atendentes'
            : 'Verifique as informações abaixo'}
        </h5>
        <ConsultaConfirmada
          senha={senha}
          consulta={
            instaQueue
              ? null
              : consultas?.find((consulta) => consulta.id === consultaConfirmed)
          }
          pendencia={instaQueue ? false : pendencia}
          sala={sala}
        />
      </div>
    </PageContainer>
  ) : (
    <PageContainer title='Agendamento' breadcrumbState={3}>
      <div className='Agendamentos'>
        <h3>Selecione abaixo seu agendamento</h3>
        <h5>Toque em uma das opções abaixo</h5>
        <div className='consultas-container'>{consultasHtml}</div>
        <h3>Confirma o agendamento?</h3>
        <div className='confirm-buttons'>
          <Button
            func={() => {
              props.clearPaciente();
              context.Identificacao.current.click();
            }}
            icon={thumbsdown}
            iconAlt='Voltar'>
            Voltar
          </Button>
          <Button
            func={confirmarConsulta}
            icon={thumbsup}
            iconAlt='Confirmar'
            zIndex={50}
            disabled={
              consultaSelected === null || consultaSelected === undefined
            }>
            Confirmar
          </Button>
        </div>
      </div>
    </PageContainer>
  );
};

const mapStoreToProps = ({ paciente, system }: AppState) => ({
  paciente,
  consultorio: system.consultorio,
  tipoAtendimento: system.tipoAtendimento
});

const mapDispatchToProps = (dispatch) => ({
  clearPaciente: () => dispatch(updatePaciente(PacienteActionTypes.CLEAR)),
  clearSystem: () =>
    dispatch(updateAtendimento(SystemActionTypes.CLEAR_ATENDIMENTO)),
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

export default connect(mapStoreToProps, mapDispatchToProps)(Agendamentos);
