import { isDate } from 'util';

export const removeTimezone = (date: Date) => {
  if (isDate(date)) {
    const data = new Date(date);
    data.setMinutes(data.getMinutes() + data.getTimezoneOffset());
    return data;
  }
  return null;
};

export const getIdade = (dataNasc: Date) =>
  isDate(dataNasc)
    ? new Date(Number(new Date()) - Number(new Date(dataNasc))).getFullYear() -
      1970
    : null;

export const addLeadingZero = (num: number): string =>
  num < 10 ? `0${num}` : `${num}`;

export const parseDate = (data: Date): string =>
  isDate(data)
    ? `${addLeadingZero(data.getDate())}/${addLeadingZero(
        data.getMonth() + 1
      )}/${data.getFullYear()}`
    : null;

export const parseTime = (data: Date): string =>
  `${addLeadingZero(data.getHours())}:${addLeadingZero(data.getMinutes())}`;

export const parseDatetime = (data: Date): string =>
  isDate(data) ? `${parseDate(data)} - ${parseTime(data)}` : null;
