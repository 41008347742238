import React, {
  useContext,
  useState,
  useCallback,
  ReactElement,
  useEffect
} from 'react';
import biometria from '../../../assets/icons/biometria blue.png';
import './ModalBiometria.scss';
import { LinkContext } from '../../../routes';
import ModalContainer from '../ModalContainer/ModalContainer';
import {
  LoadingState,
  updateLoading,
  ModalWarning,
  updateWarning
} from '../../../stores/system';
import SystemActionTypes from '../../../stores/system/system.action-types';
import { connect } from 'react-redux';
import ModalSMS from '../ModalSMS/ModalSMS';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../../environment';
import { AppState } from '../../../configureStore';
import { PacienteState } from '../../../stores/paciente';
import {
  HttpBiometricBody,
  HttpBiometricResponse
} from '../../../utils/httpTypes';
import _ from 'lodash';
import { env } from 'process';

interface Props {
  paciente: PacienteState;
  closeModal(): any;
  show: boolean;
  setLoadingStatus(status: LoadingState);
  updateWarning(warning: ModalWarning);
}

const ModalBiometria: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);
  const [sms, setSms] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const [validBio, setValidBio] = useState(false);
  const [bioData, setBioData] = useState<HttpBiometricResponse>(null);
  const validateBio = useCallback(async () => {
    if (!validBio) {
      let response: AxiosResponse<HttpBiometricResponse>;
      // if (Array.isArray(props.paciente.biometricData)) {
      //   const body: HttpBiometricBody[] = props.paciente.biometricData.map(
      //     (bioData) => ({
      //       buffer: bioData.digital,
      //       quality: bioData.qualidade,
      //       size: bioData.tamanho
      //     })
      //   );
      //   for (const bioData of body) {
      //     try {
      //       response = await axios.post<HttpBiometricResponse>(
      //         `${environment.biometricService}`,
      //         attempts < 2 ? bioData : null
      //       );
      //       break;
      //     } catch (e) {
      //       props.setLoadingStatus(LoadingState.Failed);
      //       return;
      //     }
      //   }
      // } else {
      //   const body: HttpBiometricBody = {
      //     buffer: props.paciente.biometricData?.[0].digital,
      //     quality: props.paciente.biometricData?.[0].qualidade,
      //     size: props.paciente.biometricData?.[0].tamanho
      //   };
      //   try {
      //     response = await axios.post<HttpBiometricResponse>(
      //       `${environment.biometricService}`,
      //       attempts < 2 ? body : null
      //     );
      //   } catch (e) {
      //     props.setLoadingStatus(LoadingState.Failed);
      //     return;
      //   }
      // }

      if (!props.paciente.biometricData) {
        props.updateWarning({
          text:
            environment.modalBioValid,
          title: 'Erro',
          type: 'error'
        });
        return;
      }

      const getBioData = () => {
        if (Array.isArray(props.paciente.biometricData)) {
          let bioCandidate = props.paciente.biometricData?.[0];
          props.paciente.biometricData.forEach((bioData) => {
            if (
              new Date(bioData.dataCadastro) <
              new Date(bioCandidate.dataCadastro)
            ) {
              bioCandidate = bioData;
            }
          });
          return bioCandidate;
        } else {
          return props.paciente.biometricData;
        }
      };

      const body: HttpBiometricBody = {
        buffer: getBioData().digital,
        quality: getBioData().qualidade,
        size: getBioData().tamanho
      };
      try {
        response = await axios.post<HttpBiometricResponse>(
          `${environment.biometricService}`,
          attempts < 2 ? body : null
        );
      } catch (e) {
        props.setLoadingStatus(LoadingState.Success);
        return;
      }
      if (attempts < 2) {
        if (!response?.data?.identifyResult) {
          props.updateWarning({
            text: environment.modalBiometria,
            title: 'Erro',
            type: 'error'
          });
          props.setLoadingStatus(LoadingState.Failed);
          setAttempts(attempts + 1);
          return;
        }
        props.setLoadingStatus(LoadingState.Success);
        setValidBio(true);
        props.closeModal();
        context.Agendamentos.current.click();
      } else {
        setBioData(response.data);
        setSms(true);
      }
    }
  }, [validBio, props, attempts]);

  useEffect(() => {
    if (props.show && !sms) {
      validateBio();
    }
  }, [props.show, sms]);

  const returnValue = ([
    <ModalContainer
      key={1}
      showConsulta={false}
      showTelefone={false}
      onImgClick={() => validateBio()}
      icon={biometria}
      iconAlt='Biometria'
      title='Confirmação'
      show={props.show && !sms}
      desc={`Posicione o dedo ${_.lowerFirst(
        props.paciente.biometricData?.[0].dedo
      )} da mão ${_.lowerFirst(
        props.paciente.biometricData?.[0].mao
      )} no leitor. Caso a leitura falhe, clique na digital para tentar novamente`}
      close={props.closeModal}
      leftBtn={{
        func: props.closeModal,
        title: 'Cancelar'
      }}
      // rightBtn={{
      //   func: validateBio, //func: () => context.Agendamentos.current.click(),
      //   title: 'Verificar Biometria'
      // }}
    />,
    <ModalSMS
      key={2}
      show={sms && props.show}
      bioData={bioData}
      closeModal={() => {
        setSms(false);
        props.closeModal();
      }}
    />
  ] as unknown) as ReactElement<any, any>;

  return returnValue;
};

const mapStateToProps = ({ paciente }: AppState) => ({ paciente });

const mapDispatchToProps = (dispatch) => ({
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalBiometria);
