import Axios from 'axios';

/* eslint-disable @typescript-eslint/camelcase */
interface AccessTokenHeaders {
  Authorization: string;
  'Content-Type': 'application/x-www-form-urlencoded';
}

interface AccessTokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  message?: string;
}

enum FormDataKeys {
  GrantType = 'grant_type',
  Code = 'code',
  RefreshToken = 'refresh_token'
}

enum GrantTypes {
  RefreshToken = 'refresh_token',
  AuthorizationCode = 'authorization_code'
}

const getAccessToken = (
  auth: string,
  code?: string,
  refresh_token?: string
) => {
  // const fd = new FormData();
  // fd.append(
  //   FormDataKeys.GrantType,
  //   code ? GrantTypes.AuthorizationCode : GrantTypes.RefreshToken
  // );
  // if (code) {
  //   fd.append(FormDataKeys.Code, code);
  // } else {
  //   fd.append(FormDataKeys.RefreshToken, refresh_token);
  // }
  const headers: AccessTokenHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: auth
  };
  const options = {
    headers
  };
  return Axios.post<AccessTokenResponse>(
    'https://api-athenasaude.sensedia.com/oauth/access-token',
    //'https://api-athenasaude.sensedia.com/homologacao/oauth/access-token',
    `${FormDataKeys.GrantType}=${
      code ? GrantTypes.AuthorizationCode : GrantTypes.RefreshToken
    }&${code ? FormDataKeys.Code : FormDataKeys.RefreshToken}=${
      code ? code : refresh_token
    }`,
    options
  );
};

export default getAccessToken;
