import React, { useEffect } from 'react';
import logoColorido from '../../assets/icons/logo colorido.png';
import logoBranco from '../../assets/icons/logo branco.png';
import touch from '../../assets/icons/touch.png';
import rosa from '../../assets/icons/rosa.png';
import './Inicio.scss';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import {
  updateAtendimento,
  LoadingState,
  updateLoading,
  ModalWarning,
  updateWarning,
  Logo
} from '../../stores/system';
import SystemActionTypes from '../../stores/system/system.action-types';
import { connect } from 'react-redux';
import Axios, { AxiosResponse } from 'axios';
import { AtendimentoHttp } from '../../utils/httpTypes';
import { environment } from '../../environment';
import { AppState } from '../../configureStore';

interface Props {
  updateAtendimento(fila: number);
  setLoadingStatus(status: LoadingState);
  updateWarning(warning: ModalWarning);
  showFilas: boolean;
  consultorio: number;
  logo: Logo;
  tipoAtendimento: number;
}

const Inicio: React.FC<Props> = (props) => {
  const fillFila = async () => {
    let response: AxiosResponse<AtendimentoHttp>;
    try {
      response = await Axios.get<AtendimentoHttp>(
        `${environment.proxy}/wsapichecking/services/filas-atendimento?consultorio=${props.consultorio}`,
        {
          headers: {
            Authorization: 'Basic YWRtaW46bWFzdGVy'
          }
        }
      );
    } catch (e) {
      props.updateWarning({
        text: 'Erro ao consultar filas. Entre em contato com o suporte',
        title: 'Erro',
        type: 'error'
      });
      props.setLoadingStatus(LoadingState.Failed);
      return;
    }
    if (!response.data || !response.data.dados?.length) {
      props.updateWarning({
        text: 'Erro ao consultar filas. Entre em contato com o suporte',
        title: 'Erro',
        type: 'error'
      });
    } else {
      props.updateAtendimento(response.data.dados[0]?.id);
    }
  };

  if (!props.tipoAtendimento) {
    fillFila();
  }

  useEffect(() => {
    if (!props.showFilas) {
      fillFila();
    }
    // eslint-disable-next-line
  }, [props.consultorio]);

  return (
    <Link to={props.showFilas ? Routes.Filas : Routes.Identificacao}>
      <div className='Inicio'>
        {props.logo === Logo.SAMP ? (
          <img src={logoColorido} alt='Logo' id='logo' />
        ) : (
          ''
        )}
        <img src={rosa} alt='Rosa esquerda' className='rosa--esquerda' />
        <img
          src={rosa}
          alt='Rosa esquerda'
          className='rosa--esquerda'
          id='complete'
        />
        <img src={rosa} alt='Rosa direita' id='rosa--direita' />
        <div id='comecar-container'>
          <img src={touch} alt='Iniciar atendimento' id='touch' />
          <h3>Autoatendimento</h3>
          <h6>Toque na tela para iniciar</h6>
          {props.logo === Logo.SAMP ? (
            <img src={logoBranco} alt='Logo branco' id='logo-branco' />
          ) : (
            ''
          )}
        </div>
      </div>
    </Link>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateAtendimento: (fila: number) =>
    dispatch(
      updateAtendimento(SystemActionTypes.UPDATE_ATENDIMENTO, {
        tipoAtendimento: fila
      })
    ),
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

const mapStateToProps = ({ system }: AppState) => ({
  consultorio: system.consultorio,
  tipoAtendimento: system.tipoAtendimento,
  logo: system.logo,
  showFilas: system.showFilas
});

export default connect(mapStateToProps, mapDispatchToProps)(Inicio);
