import React from 'react';
import './Identificacao.scss';
import PageContainer from '../../components/PageContainer/PageContainer';
import Button from '../../components/Button/Button';
import cpf from '../../assets/icons/cpf.png';
import wizard from '../../assets/icons/wizard.png';
import bio from '../../assets/icons/biometria.png';
import mulher from '../../assets/icons/mulher.png';
import ModalCPF from '../../components/modals/ModalCPF/ModalCPF';
import ModalCartaoConvenio from '../../components/modals/ModalCartaoConvenio/ModalCartaoConvenio';
import ModalNomeDataNascimento from '../../components/modals/ModalNomeDataNascimento/ModalNomeDataNascimento';
import ModalQRCode from '../../components/modals/ModalQRCode/ModalQRCode';
import { LinkContext } from '../../routes';
import ModalBiometria from '../../components/modals/ModalBiometria/ModalBiometria';
import ModalReconFacial from '../../components/modals/ModalReconFacial/ModalReconFacial';

export enum Modals {
  CPF = 0,
  CartaoConvenio,
  NomeDataNascimento,
  QRCode,
  Biometria,
  ReconhecimentoFacial
}

export const SSKey = 'IdentificacaoRedirect';

interface State {
  modal: Modals;
  bio: boolean;
}

export default class Identificacao extends React.Component<{}, State> {
  static contextType = LinkContext;
  constructor(props) {
    super(props);
    const modal = sessionStorage.getItem(SSKey);
    this.state = { modal: modal ? Number(modal) : null, bio: false };
    sessionStorage.removeItem(SSKey);
  }

  closeModal = () => this.setState({ modal: null });

  render() {
    const defaultButtons = [
      // <Button
      //   desc='Já possuo meu código'
      //   key={1}
      //   icon={wizard}
      //   iconAlt='Wizard'
      //   big={true}
      //   func={() => this.setState({ modal: Modals.QRCode })}>
      //   Check-in ágil
      // </Button>,
      <Button
        key={2}
        icon={cpf}
        iconAlt='CPF'
        big={true}
        func={() => this.setState({ modal: Modals.CPF })}>
        Por CPF
      </Button>,
      // <Button
      //   key={2}
      //   icon={cartao}
      //   iconAlt='Cartao de Convenio'
      //   big={true}
      //   func={() => this.setState({ modal: Modals.CartaoConvenio })}>
      //   Cartão de Convênio
      // </Button>,
      // <Button
      //   key={3}
      //   icon={nomeDataNasc}
      //   iconAlt='Nome e data de nascimento'
      //   big={true}
      //   func={() => this.setState({ modal: Modals.NomeDataNascimento })}>
      //   Nome e data de nascimento
      // </Button>,
      [
        <h5 key={0} style={{ marginTop: '21px' }}>
          Selecione "Senha de atendimento" para falar com um de nossos atendentes:
        </h5>,
        <Button
          key={4}
          icon={mulher}
          iconAlt='Senha'
          big={true}
          func={() => this.context.Agendamentos.current.click()}>
          Senha de atendimento
        </Button>
      ]
    ];
    const bioButtons = [
      <Button
        key={5}
        icon={bio}
        iconAlt='Biometria'
        big={true}
        func={() => this.setState({ modal: Modals.Biometria })}>
        Biometria
      </Button>
    ];
    return (
      <PageContainer title='Identificação' breadcrumbState={2}>
        <ModalCPF
          closeModal={this.closeModal}
          show={this.state.modal === Modals.CPF}
          setAuthMode={() => this.setState({ modal: null, bio: true })}
        />
        <ModalCartaoConvenio
          closeModal={this.closeModal}
          show={this.state.modal === Modals.CartaoConvenio}
          setAuthMode={() => this.setState({ modal: null, bio: true })}
        />
        <ModalNomeDataNascimento
          closeModal={this.closeModal}
          show={this.state.modal === Modals.NomeDataNascimento}
          setAuthMode={() => this.setState({ modal: null, bio: true })}
        />
        <ModalQRCode
          closeModal={this.closeModal}
          show={this.state.modal === Modals.QRCode}
          setAuthMode={() => this.setState({ modal: null, bio: true })}
        />
        <ModalBiometria
          closeModal={this.closeModal}
          show={this.state.modal === Modals.Biometria}
        />
        <ModalReconFacial
          closeModal={this.closeModal}
          show={this.state.modal === Modals.ReconhecimentoFacial}
        />
        <div className='Identificacao'>
          <h3>Identifique-se para prosseguir com o atendimento</h3>
          <h5>Selecione "Por CPF" para confirmar a sua presença na consulta agendada:</h5>
          {this.state.bio ? bioButtons : defaultButtons}
        </div>
      </PageContainer>
    );
  }
}
