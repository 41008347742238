import React, { useContext } from 'react';
import './ModalQRCode.scss';
import qrcode from '../../../assets/icons/qr.png';
import ModalSingleInput from '../ModalSingleInput/ModalSingleInput';
import { environment } from '../../../environment';
import { AppState } from '../../../configureStore';
import { connect } from 'react-redux';
import { LinkContext } from '../../../routes';

interface Props {
  closeModal(): any;
  show: boolean;
  setAuthMode: () => any;
  consultorio: number;
}

const ModalQRCode: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);

  return (
    <ModalSingleInput
      qrscan={false}
      showConsulta={true}
      redirect={() => context.Agendamentos.current.click()}
      closeModal={() => {
        context.Filas.current.click();
        props.closeModal();
      }}
      desc='Digite seu código abaixo ou posicione seu QR code em frente a câmera'
      icon={qrcode}
      qr={true}
      iconAlt='QR Code'
      route={`${environment.proxy}/wsapichecking/services/filtro-agendamento?consultorio=${props.consultorio}&agendamento_id`}
      show={props.show}
      title='Confirmação'
      validity={[
        (inputEl) => {
          if (!inputEl.value) {
            inputEl.setCustomValidity('Esse campo é obrigatório');
            inputEl.reportValidity();
            return false;
          }
          return true;
        }
      ]}
    />
  );
};

const mapStateToProps = ({ system }: AppState) => ({
  consultorio: system.consultorio
});

export default connect(mapStateToProps)(ModalQRCode);
