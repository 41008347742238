import React, { useRef, useEffect, useState } from 'react';
import './ModalConfiguracoes.scss';
import Backdrop from '../../Backdrop/Backdrop';
import Button from '../../Button/Button';
import {
  updateConsultorio,
  updateFilas,
  updateLoading,
  updateLogo,
  updateWarning
} from '../../../stores/system/system.actions';
import SystemActionTypes from '../../../stores/system/system.action-types';
import { connect } from 'react-redux';
import { AppState } from '../../../configureStore';
import { LoadingState, Logo, ModalWarning } from '../../../stores/system';
import Input from '../../Input/Input';
import { LocalStorageKeys, clearCheckin } from '../../../utils/localStorage';
import Axios, { AxiosResponse } from 'axios';
import { environment } from '../../../environment';

interface Props {
  updateConsultorio(consultorio: number): any;
  updateLogo(logo: Logo): any;
  updateFilas(showFilas: boolean): any;
  setLoadingStatus(status: LoadingState);
  updateWarning(warning: ModalWarning);
  show: boolean;
  showFilas: boolean;
  logo: Logo;
  consultorio: number;
  close: () => any;
}

interface Consultorio {
  dados: {
    id: number;
    nome: string;
  }[];
}

const ModalConfiguracoes: React.FC<Props> = (props) => {
  const selectConsultorio = useRef<HTMLSelectElement>(null);
  const selectLogo = useRef<HTMLSelectElement>(null);
  const selectFilas = useRef<HTMLInputElement>(null);
  const inputSenha = useRef<Input>(null);
  const [auth, setAuth] = useState(false);
  const [, updateState] = useState();
  const senha = 'totem2020';
  const [consultorios, setConsultorios] = useState<Consultorio>(null);

  useEffect(() => {
    setAuth(false);
    const fillFila = async () => {
      let response: AxiosResponse<Consultorio>;
      try {
        response = await Axios.get<Consultorio>(
          `${environment.proxy}/wsapichecking/services/consultorios`,
          {
            headers: {
              Authorization: 'Basic YWRtaW46bWFzdGVy'
            }
          }
        );
      } catch (e) {
        props.updateWarning({
          text:
            'Erro ao consultar consultórios. Entre em contato com o suporte',
          title: 'Erro',
          type: 'error'
        });
        props.setLoadingStatus(LoadingState.Failed);
        return;
      }
      if (!response.data || !response.data.dados?.length) {
        props.updateWarning({
          text:
            'Erro ao consultar consultórios. Entre em contato com o suporte',
          title: 'Erro',
          type: 'error'
        });
      } else {
        setConsultorios(response.data);
      }
    };
    fillFila();
  }, [props.show]);

  return (
    <Backdrop show={props.show} zIndex={500}>
      {auth ? (
        <div className='modal-conf'>
          <h3>Configurações</h3>
          <h6>Consultório</h6>
          <select defaultValue={props.consultorio} ref={selectConsultorio}>
            {consultorios.dados.map((consultorio, index) => (
              <option key={index} value={consultorio.id}>
                {consultorio.id} - {consultorio.nome}
              </option>
            ))}
            {/* <option value={73960}>73960 - Clínica Samp Vitória</option>
            <option value={866962}>
              866962 - Ambulatório - 07 de Setembro
            </option>
            <option value={104}>104 - Cínica Oncológica</option>
            <option value={73978}>73978 - Clínica Samp Serra</option>
            <option value={73966}>73966 - Clínica Samp Cariacica</option>
            <option value={73995}>73995 - Clínica da Mulher</option>
            <option value={3}>3 - Clínica Vila Velha</option>
            <option value={102}>
              102 - Clínica de Especialidades de Vitória
            </option> */}
          </select>
          <h6>Logo</h6>
          <select defaultValue={props.logo} ref={selectLogo}>
            {Object.entries(Logo)
              .splice(0, Object.entries(Logo).length / 2)
              .map((logo, index) => (
                <option key={index} value={logo[0]}>
                  {logo[1]}
                </option>
              ))}
          </select>
          <label htmlFor='filas'>
            <div className='flex-container'>
              <h6>Mostrar tela de filas</h6>
              <input
                id='filas'
                type='checkbox'
                defaultChecked={props.showFilas}
                ref={selectFilas}
              />
            </div>
          </label>
          <div className='flex-container counter'>
            <h6>
              Check-ins realizados:{' '}
              {Number(localStorage.getItem(LocalStorageKeys.checkIns))}
            </h6>
            <Button
              func={() => {
                clearCheckin();
                updateState(null);
              }}>
              Zerar
            </Button>
          </div>

          <Button
            func={() => {
              props.updateConsultorio(Number(selectConsultorio.current.value));
              props.updateLogo(Number(selectLogo.current.value));
              props.updateFilas(selectFilas.current.checked);
              props.close();
            }}>
            OK
          </Button>
        </div>
      ) : (
        <div className='modal-conf'>
          <h3>Configurações</h3>
          <h6>Senha</h6>
          <Input ref={inputSenha} type='password' />
          <div className='flex-container btn-container'>
            <Button
              func={() => {
                if (inputSenha.current.inputElRef.current.value === senha) {
                  setAuth(true);
                } else {
                  inputSenha.current.inputElRef.current.setCustomValidity(
                    'Senha incorreta'
                  );
                  inputSenha.current.inputElRef.current.reportValidity();
                }
              }}>
              Autenticar
            </Button>
            <Button func={props.close}>Fechar</Button>
          </div>
        </div>
      )}
    </Backdrop>
  );
};

const mapStateToProps = ({ system }: AppState) => ({
  consultorio: system.consultorio,
  logo: system.logo,
  showFilas: system.showFilas
});

const mapDispatchToProps = (dispatch) => ({
  updateConsultorio: (consultorio: number) =>
    dispatch(
      updateConsultorio(SystemActionTypes.UPDATE_CONSULTORIO, { consultorio })
    ),
  updateLogo: (logo: Logo) =>
    dispatch(updateLogo(SystemActionTypes.UPDATE_LOGO, { logo })),
  updateFilas: (showFilas: boolean) =>
    dispatch(updateFilas(SystemActionTypes.UPDATE_FILAS, { showFilas })),
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfiguracoes);
