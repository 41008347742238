import React, { useContext } from 'react';
import './ModalCPF.scss';
import cpf from '../../../assets/icons/cpf blue.png';
import { environment } from '../../../environment';
import ModalSingleInput from '../ModalSingleInput/ModalSingleInput';
import { AppState } from '../../../configureStore';
import { connect } from 'react-redux';
import { PacienteState } from '../../../stores/paciente';
import { LinkContext } from '../../../routes';

interface Props {
  closeModal(): any;
  show: boolean;
  setAuthMode: () => any;
  consultorio: number;
  paciente: PacienteState;
}

const ModalCPF: React.FC<Props> = (props) => {
  const linkContext = useContext(LinkContext);
  return (
    <ModalSingleInput
      redirect={() => {
        linkContext.Agendamentos.current.click(); //INHA PARA PASSAR BIOMETRIA
        if (props.paciente.usarBiometria) {
          props.setAuthMode();
        } else {
          linkContext.Agendamentos.current.click();
        }
      }}
      //redirect={() => context.Agendamentos.current.click()}
      maxlength={11}
      closeModal={props.closeModal}
      desc='Digite seu CPF (apenas números)'
      icon={cpf}
      iconAlt='CPF'
      route={`${environment.proxy}/wsapichecking/services/filtro-agendamento?consultorio=${props.consultorio}&cpf`}
      show={props.show}
      title='Confirmação'
      type='number'
      validity={[
        (inputEl) => {
          if (!inputEl.value || inputEl.value.replace(/\D/g, '').length < 11) {
            inputEl.setCustomValidity(
              'Um CPF possui 11 dígitos. Verifique o número informado.'
            );
            inputEl.reportValidity();
            return false;
          }
          return true;
        },
        (inputEl) => {
          const validaCPF = (cpf: string | number) => {
            cpf = String(cpf).replace(/[^\d]+/g, '');
            if (cpf === '') {
              return false;
            }
            // Elimina CPFs invalidos conhecidos
            if (
              cpf.length !== 11 ||
              cpf === '00000000000' ||
              cpf === '11111111111' ||
              cpf === '22222222222' ||
              cpf === '33333333333' ||
              cpf === '44444444444' ||
              cpf === '55555555555' ||
              cpf === '66666666666' ||
              cpf === '77777777777' ||
              cpf === '88888888888' ||
              cpf === '99999999999'
            ) {
              return false;
            }
            // Valida 1o digito
            let add = 0;
            for (let i = 0; i < 9; i++) {
              add += parseInt(cpf.charAt(i), 10) * (10 - i);
            }
            let rev = 11 - (add % 11);
            if (rev === 10 || rev === 11) {
              rev = 0;
            }
            if (rev !== parseInt(cpf.charAt(9), 10)) {
              return false;
            }
            // Valida 2o digito
            add = 0;
            for (let i = 0; i < 10; i++) {
              add += parseInt(cpf.charAt(i), 10) * (11 - i);
            }
            rev = 11 - (add % 11);
            if (rev === 10 || rev === 11) {
              rev = 0;
            }
            if (rev !== parseInt(cpf.charAt(10), 10)) {
              return false;
            }
            return true;
          };
          if (!validaCPF(inputEl.value.replace(/\D/g, ''))) {
            inputEl.setCustomValidity(
              'O CPF informado é inválido. Verifique o número informado.'
            );
            inputEl.reportValidity();
            return false;
          }
          return true;
        }
      ]}
    />
  );
};

const mapStateToProps = ({ system, paciente }: AppState) => ({
  consultorio: system.consultorio,
  paciente
});

export default connect(mapStateToProps)(ModalCPF);
