import React from 'react';
import './App.scss';
import {
  Route,
  RouteComponentProps,
  withRouter,
  Redirect,
  Link
} from 'react-router-dom';
import Inicio from './pages/Inicio/Inicio';
import Filas from './pages/Filas/Filas';
import Identificacao from './pages/Identificacao/Identificacao';
import Agendamentos from './pages/Agendamentos/Agendamentos';
import logoConfig from './assets/icons/configuracoes.png';
import { Routes, LinkContext } from './routes';
import RosaDiagonal from './components/RosaDiagonal/RosaDiagonal';
import Loading from './components/Loading/Loading';
import RouteAnimator from './components/RouteAnimator/RouteAnimator';
import ModalWarning from './components/modals/ModalWarning/ModalWarning';
import Atendimento from './pages/Atendimento/Atendimento';
import ModalConfiguracoes from './components/modals/ModalConfiguracoes/ModalConfiguracoes';
import Axios from 'axios';
import { triggerHook } from './webhook-logger';
import { SystemState, updateAuth } from './stores/system';
import getGrantCode from './utils/auth/grant-code';
import getAccessToken from './utils/auth/access-token';
import cron from 'cron';
import { Dispatch } from 'redux';
import { AppState } from './configureStore';
import { connect } from 'react-redux';
import SystemActionTypes from './stores/system/system.action-types';

class App extends React.Component<
  RouteComponentProps & {
    system: SystemState;
    updateAuth: (payload: {
      expires_in?: number;
      access_token?: string;
      refresh_token?: string;
      grant_code?: string;
    }) => void;
  },
  { confOpen: boolean }
> {
  static contextType = LinkContext;

  constructor(props) {
    super(props);
    Axios.interceptors.response.use(
      (response) => {
        triggerHook(JSON.stringify(response));
        return response;
      },
      (error) => {
        triggerHook('ERROR!!! \n' + JSON.stringify(error));
        return error;
      }
    );
    this.state = { confOpen: false };
    if (!this.props.system.access_token) {
      this.getToken();
    }
    const refreshJob = new cron.CronJob(
      '0 0 0 * * *',
      this.getToken,
      null,
      true,
      'America/Sao_Paulo'
    );
    refreshJob.start();
  }

  getToken = async () => {
    let authTokenResponse;
    if (this.props.system.refresh_token) {
      authTokenResponse = await getAccessToken(
        this.props.system.authorization_bs64,
        null,
        this.props.system.refresh_token
      );
    } else {
      const grantCodeResponse = await getGrantCode(this.props.system.client_id);
      const code = new URL(
        grantCodeResponse.data.redirect_uri
      ).searchParams.get('code');
      authTokenResponse = await getAccessToken(
        this.props.system.authorization_bs64,
        code
      );
    }
    this.props.updateAuth(authTokenResponse.data);
  };

  redirect = (path: string) => {
    return Object.values(Routes).findIndex((route) => route === path) === -1 ? (
      <Redirect to={Routes.Inicio} />
    ) : (
      ''
    );
  };

  render() {
    return (
      <div id='root-container'>
        {this.redirect(this.props.location.pathname)}
        <RosaDiagonal />
        <ModalWarning />
        <ModalConfiguracoes
          show={this.state.confOpen}
          close={() => this.setState({ confOpen: false })}
        />
        <div id='app'>
          <Loading />
          <img
            src={logoConfig}
            alt='Configurações'
            id='config'
            onClick={() => this.setState({ confOpen: true })}
          />
          <Route path={Routes.Inicio}>
            {(route: RouteComponentProps) => {
              return (
                <RouteAnimator id='Inicio' show={!!route.match}>
                  <Inicio />
                </RouteAnimator>
              );
            }}
          </Route>
          <Route exact path={Routes.Filas}>
            {(route: RouteComponentProps) => {
              return (
                <RouteAnimator id='Filas' show={!!route.match}>
                  <Filas />
                </RouteAnimator>
              );
            }}
          </Route>
          <Route exact path={Routes.Identificacao}>
            {(route: RouteComponentProps) => {
              return (
                <RouteAnimator id='Identificacao' show={!!route.match}>
                  <Identificacao />
                </RouteAnimator>
              );
            }}
          </Route>
          <Route exact path={Routes.Agendamentos}>
            {(route: RouteComponentProps) => {
              return (
                <RouteAnimator id='Agendamentos' show={!!route.match}>
                  <Agendamentos />
                </RouteAnimator>
              );
            }}
          </Route>
          <Route exact path={Routes.Atendimento}>
            {(route: RouteComponentProps) => {
              return (
                <RouteAnimator id='Atendimento' show={!!route.match}>
                  <Atendimento />
                </RouteAnimator>
              );
            }}
          </Route>
          <Link
            to={Routes.Inicio}
            className='hidden'
            ref={this.context.Inicio}
          />
          <Link to={Routes.Filas} className='hidden' ref={this.context.Filas} />
          <Link
            to={Routes.Identificacao}
            className='hidden'
            ref={this.context.Identificacao}
          />
          <Link
            to={Routes.Agendamentos}
            className='hidden'
            ref={this.context.Agendamentos}
          />
          <Link
            to={Routes.Atendimento}
            className='hidden'
            ref={this.context.Atendimento}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ system }: AppState) => ({
  system
});

const mapDispatchToProps = (
  dispatch: Dispatch
): {
  updateAuth: (payload: {
    expires_in?: number;
    access_token?: string;
    refresh_token?: string;
    grant_code?: string;
  }) => void;
} => ({
  updateAuth: (payload: {
    expires_in?: number;
    access_token?: string;
    refresh_token?: string;
    grant_code?: string;
  }) => dispatch(updateAuth(SystemActionTypes.UPDATE_AUTH, payload)) as any
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
