import { ActionData } from '../../utils/reduxTypes';
import PacienteActionTypes from './paciente.action-types';
import { PacienteState, ConsultaParsed } from './paciente.store';
import { IdentificacaoHttp } from '../../utils/httpTypes';

interface UpdatePaciente {
  /**
   * Uses an `Action Creator` to build an `Action` to mutate `PacienteStore's state
   *
   * This implementation changes both the `status` and the `content` of the store based on the `payload`
   *
   * @param type One of the available `Action Types` to be interpreted by the `Reducer`
   *
   * @param payload The content to be saved on the `PacienteStore`
   *
   * @returns An `Action` ready to be dispatched
   */
  (
    type: PacienteActionTypes.LOADED,
    payload:
      | (IdentificacaoHttp & { notFound?: boolean; authQr?: boolean })
      | PacienteState
  ): ActionData<PacienteActionTypes, PacienteState>;
  /**
   * Uses an `Action Creator` to build an `Action` to mutate `PacienteStore's state
   *
   * This implementation doesn't need a payload since the reducer doesn't use it, it just resets the `content` and sets the `status`
   *
   * @param type One of the available `Action Types` to be interpreted by the `Reducer`
   *
   * @returns An `Action` ready to be dispatched
   */
  (
    type:
      | PacienteActionTypes.CLEAR
      | PacienteActionTypes.LOADING
      | PacienteActionTypes.FAILED
  ): ActionData<PacienteActionTypes>;
}

const isExternalData = (obj: any): obj is IdentificacaoHttp => !!obj?.dados;

export const updatePaciente: UpdatePaciente = (
  type: PacienteActionTypes,
  payload?:
    | (IdentificacaoHttp & { notFound?: boolean; authQr?: boolean })
    | PacienteState
): ActionData<PacienteActionTypes, PacienteState> => {
  if (isExternalData(payload)) {
    const dados = payload.dados;
    const parseData = (data: string, hora: string) => {
      const dataSplitted = data.split(' ');
      const dateStr = [dataSplitted[0], hora].join(' ');
      return dateStr;
    };
    const parsedPayload: PacienteState = {
      consulta: dados.consultas.map<ConsultaParsed>((consulta) => ({
        data: new Date(parseData(consulta.dt_agenda, consulta.hr_agendamento)),
        item: consulta.desc_tipoagendamento,
        itemAbrev: consulta.desc_tipoagendamento,
        medico: consulta.medico,
        especialidade: consulta.especialidade,
        sala: null,
        critica: consulta.critica,
        agendamento: consulta.agendamento_id
      })),
      usarBiometria: dados.usar_biometria,
      convenio: dados.cartao,
      cpf: null,
      dataNascimento: new Date(dados.data_nascimento),
      nome: dados.nome_paciente,
      senhaAtendimento: null,
      sala: null,
      telefone: Number(dados.paciente_celular),
      authQr: payload.authQr,
      notFound: false,
      status: type
    };
    return { type, payload: parsedPayload };
  } else {
    return { type, payload };
  }
};

export const updateBio = (
  payload: Required<Pick<PacienteState, 'biometricData'>>,
  type = PacienteActionTypes.BIOMETRICS
) => ({ type, payload });
