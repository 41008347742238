import React from 'react';
import './Filas.scss';
import PageContainer from '../../components/PageContainer/PageContainer';
import Button from '../../components/Button/Button';
import {
  LoadingState,
  updateLoading,
  ModalWarning,
  updateWarning,
  updateAtendimento
} from '../../stores/system';
import SystemActionTypes from '../../stores/system/system.action-types';
import { connect } from 'react-redux';
import Input from '../../components/Input/Input';
import { updatePaciente, PacienteState } from '../../stores/paciente';
import PacienteActionTypes from '../../stores/paciente/paciente.action-types';
import { LinkContext } from '../../routes';
import { AtendimentoHttp, IdentificacaoHttp } from '../../utils/httpTypes';
import { environment } from '../../environment';
import Axios, { AxiosResponse } from 'axios';
import { AppState } from '../../configureStore';

interface Button {
  name: string;
  desc: string;
  icon: 'schedule' | 'person';
  auth: boolean;
}

interface State {
  buttons: Button[];
  modalOpen: boolean;
  filas: AtendimentoHttp;
}

interface Props {
  setLoadingStatus(status: LoadingState);
  setDadosPaciente(
    type: PacienteActionTypes,
    payload: IdentificacaoHttp | PacienteState
  );
  updateWarning(warning: ModalWarning);
  updateAtendimento(fila: number);
  consultorio: number;
}

class Filas extends React.Component<Props, State> {
  static contextType = LinkContext;
  modalOpen = true;
  inputCodigo = React.createRef<Input>();

  constructor(props) {
    super(props);
    this.state = { buttons: [], modalOpen: false, filas: null };
    const fillFila = async () => {
      props.setLoadingStatus(LoadingState.Loading);
      let response: AxiosResponse<AtendimentoHttp>;
      try {
        response = await Axios.get<AtendimentoHttp>(
          `${environment.proxy}/wsapichecking/services/filas-atendimento?consultorio=${this.props.consultorio}`,
          {
            headers: {
              Authorization: 'Basic YWRtaW46bWFzdGVy'
            }
          }
        );
      } catch (e) {
        props.updateWarning({
          text: 'Erro ao consultar filas. Entre em contato com o suporte',
          title: 'Erro',
          type: 'error'
        });
        props.setLoadingStatus(LoadingState.Failed);
        return;
      }
      if (!response.data || !response.data.dados?.length) {
        props.updateWarning({
          text: 'Erro ao consultar filas. Entre em contato com o suporte',
          title: 'Erro',
          type: 'error'
        });
        props.setLoadingStatus(LoadingState.Failed);
      } else {
        // props.updateAtendimento(response.data.dados[0]?.id);
        this.setState({ filas: response.data });
        props.setLoadingStatus(LoadingState.Success);
      }
    };
    fillFila();
  }

  invertModal = () =>
    this.setState((prevState) => ({
      ...prevState,
      modalOpen: !prevState.modalOpen
    }));

  render() {
    return (
      <PageContainer title='Filas de auto-atendimento' breadcrumbState={1}>
        <div className='Filas'>
          <h3>Olá! Seja Bem-vindo!</h3>
          <h5>
            Para encaminhar seu atendimento, tenha seu código em mãos e
            selecione uma das filas abaixo:
          </h5>
          {this.state.filas
            ? this.state.filas.dados.map((fila, index) => (
                <Button
                  key={index}
                  big={true}
                  func={() => {
                    this.props.updateAtendimento(fila.id);
                    this.context.Identificacao.current.click();
                  }}>
                  {fila.nome}
                </Button>
              ))
            : null}

          {/* <Button
            desc='Já possuo meu código'
            icon={wizard}
            iconAlt='Wizard'
            big={true}
            func={() => {
              sessionStorage.setItem(SSKey, String(Modals.QRCode));
              this.context.Identificacao.current.click();
            }}>
            Check-in ágil
          </Button>
          <Button
            icon={cpf}
            iconAlt='CPF'
            big={true}
            func={() => {
              sessionStorage.setItem(SSKey, String(Modals.CPF));
              this.context.Identificacao.current.click();
            }}>
            Por CPF
          </Button> */}
          {/* <h5 style={{ marginTop: '21px' }}>
            Selecione essa opção para falar com nossos atendentes:
          </h5>
          <Button
            icon={atendimento}
            iconAlt='Atendimento'
            big={true}
            func={() => {
              sessionStorage.removeItem(SSKey);
              this.context.Agendamentos.current.click();
            }}>
            Senha de atendimento
          </Button> */}
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = ({ system }: AppState) => ({
  consultorio: system.consultorio
});

const mapDispatchToProps = (dispatch) => ({
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning })),
  setDadosPaciente: (type, payload) =>
    dispatch(updatePaciente(type as PacienteActionTypes.LOADED, payload)),
  updateAtendimento: (fila: number) =>
    dispatch(
      updateAtendimento(SystemActionTypes.UPDATE_ATENDIMENTO, {
        tipoAtendimento: fila
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(Filas);
