import React from 'react';
import './Input.scss';
import InputMask from 'react-input-mask';

interface Props {
  type: 'text' | 'number' | 'date' | 'password';
  title?: string;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  mask?: string;
  keydown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
}

export default class Input extends React.Component<Props> {
  inputElRef = React.createRef<HTMLInputElement>();

  render() {
    const inputMask = () =>
      this.props.required ? (
        <input
          placeholder={this.props.placeholder}
          type={this.props.type}
          className='modal-input'
          pattern={this.props.pattern}
          ref={this.inputElRef}
          required
        />
      ) : (
        <input
          placeholder={this.props.placeholder}
          type={this.props.type}
          className='modal-input'
          pattern={this.props.pattern}
          ref={this.inputElRef}
        />
      );
    const inputPlain = this.props.required ? (
      <input
        placeholder={this.props.placeholder}
        type={this.props.type}
        className='modal-input'
        pattern={this.props.pattern}
        ref={this.inputElRef}
        onChange={this.props.onChange}
        onKeyDown={this.props.keydown}
        required
      />
    ) : (
      <input
        placeholder={this.props.placeholder}
        type={this.props.type}
        className='modal-input'
        pattern={this.props.pattern}
        ref={this.inputElRef}
        onChange={this.props.onChange}
        onKeyDown={this.props.keydown}
      />
    );
    const inputRender = this.props.mask ? inputMask : inputPlain;
    return (
      <div className='Input'>
        {this.props.title ? <h6>{this.props.title}</h6> : ''}
        {this.props.mask ? (
          <InputMask mask={this.props.mask} onChange={this.props.onChange}>
            {inputRender}
          </InputMask>
        ) : (
          inputRender
        )}
      </div>
    );
  }
}
