type Env = {
  baseUrl: { [P in typeof process.env.NODE_ENV]: string };
  proxy: string;
  fingerprint: string;
  biometricDatabase: string;
  biometricService: string;
  tokenService: string;
  webhook: string;
  consultaPaciente: string;
  modalBiometria: string;
  modalToken: string;
  modalBioValid: string;

};

type Environment = Env & { baseUrl: { toString: () => string } };

const env: Env = {
  baseUrl: {
    development:
      process.env.REACT_APP_MOCK === 'true'
        ? 'http://localhost:3001'
        : 'http://mockathena.hammerconsult.com.br',
    production: 'http://mockathena.hammerconsult.com.br',
    test: 'http://localhost:3001'
  },
  fingerprint:
    process.env.REACT_APP_MOCK === 'true'
      ? 'http://localhost:4001/fingerprint'
      : 'http://localhost:80/fingerprint/fingerprint/GetFingerprint',
  biometricDatabase:
    //'https://biometria-hml.samp.com.br/elegibilidade-athenas/biometria',
    'http://biometria.samp.com.br:8080/elegibilidade-athenas/biometria',
  biometricService: 'http://localhost:3110/api/v1/biometric/identify',
  tokenService:
    'https://api-athenasaude.sensedia.com/biometria-athena/v1/tokens',
    //'https://api-athenasaude.sensedia.com/homologacao/biometria-athena/v1/tokens',    
  proxy:
    process.env.REACT_APP_MOCK === 'true'
      ? 'http://localhost:4001'
      : 'https://api-mkweb.athenasaude.com.br',
     // : 'https://api-hml-mkweb.athenasaude.com.br',
  webhook:
    'https://discordapp.com/api/webhooks/750798135663788174/i11XdC7IJVI8DqdqFU0mRnkVHRyK6WuSmxBS8fw7AazEZGwdg8WFuJ4tLoGRzcI3f_Ze',
  consultaPaciente: 'Erro ao consultar paciente. Favor voltar a tela inicial e gerar uma senha para atendimento na recepção.',
  modalBiometria: 'Erro na leitura da biometria. Favor voltar a tela inicial e gerar uma senha para atendimento na recepção.',
  modalToken: 'Erro ao validar token. Favor voltar a tela inicial e gerar uma senha para atendimento na recepção',
  modalBioValid: 'Erro ao validar token. Favor voltar a tela inicial e gerar uma senha para atendimento na recepção.'
};

const getEnvBaseUrl = (): string => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return env.baseUrl.development;
    case 'production':
      return env.baseUrl.production;
    case 'test':
      return env.baseUrl.test;
  }
};

export const environment: Environment = {
  baseUrl: { ...env.baseUrl, toString: getEnvBaseUrl },
  proxy: env.proxy,
  fingerprint: env.fingerprint,
  biometricDatabase: env.biometricDatabase,
  biometricService: env.biometricService,
  tokenService: env.tokenService,
  webhook: env.webhook,
  consultaPaciente: env.consultaPaciente,
  modalBiometria: env.modalBiometria,
  modalToken: env.modalToken,
  modalBioValid: env.modalBioValid
};
