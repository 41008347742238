export enum LocalStorageKeys {
  checkIns = 'checkins'
}

export const incrementCheckin = () =>
  localStorage.setItem(
    LocalStorageKeys.checkIns,
    String(Number(localStorage.getItem(LocalStorageKeys.checkIns)) + 1)
  );
export const clearCheckin = () =>
  localStorage.setItem(LocalStorageKeys.checkIns, '0');
