import React from 'react';
import './Loading.scss';
import { ReactComponent as LoadingSvg } from '../../assets/icons/loading.svg';
import { connect } from 'react-redux';
import { AppState } from '../../configureStore';
import { LoadingState } from '../../stores/system';
import Backdrop from '../Backdrop/Backdrop';

interface Props {
  loading: LoadingState;
}

class Loading extends React.Component<Props> {
  render() {
    return (
      <Backdrop
        show={this.props.loading === LoadingState.Loading}
        zIndex={1000}>
        <LoadingSvg />
      </Backdrop>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.system.loading
});

export default connect(mapStateToProps)(Loading);
