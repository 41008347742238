import React from 'react';

export enum Routes {
  Filas = '/filas',
  Identificacao = '/identificacao',
  Agendamentos = '/Agendamentos',
  Inicio = '/Inicio',
  Atendimento = '/Atendimento'
}

export type RouteLinks = {
  [P in keyof typeof Routes]: React.RefObject<HTMLAnchorElement>;
};

export const routeLinks: RouteLinks = {
  Agendamentos: React.createRef<HTMLAnchorElement>(),
  Filas: React.createRef<HTMLAnchorElement>(),
  Identificacao: React.createRef<HTMLAnchorElement>(),
  Inicio: React.createRef<HTMLAnchorElement>(),
  Atendimento: React.createRef<HTMLAnchorElement>()
};

export const LinkContext = React.createContext<RouteLinks>(null);
