import React from 'react';
import './Button.scss';

interface Props {
  func: () => any;
  disabled?: boolean;
  id?: string;
  cssh3Class?: string;
  desc?: string;
  icon?: string;
  iconAlt?: string;
  big?: boolean;
  zIndex?: number;
}

const Button: React.FC<Props> = (props) => (
  <button
    className={`Button${props.cssh3Class ? ' ' + props.cssh3Class : ''}${
      props.disabled ? ' disabled' : ''
    }`}
    id={props.id}
    style={props.zIndex ? { zIndex: props.zIndex } : null}
    onClick={!props.disabled ? props.func : null}>
    <div className='button-content'>
      {props.icon && props.iconAlt ? (
        <img src={props.icon} alt={props.iconAlt} />
      ) : (
        ''
      )}
      <div className='button-text'>
        <h3
          className={`${props.desc || props.big ? 'big' : ''} ${
            !props.cssh3Class ? 'default' : ''
          }`}>
          {props.children}
        </h3>
        {props.desc ? <h6>{props.desc}</h6> : ''}
      </div>
    </div>
  </button>
);

export default Button;
