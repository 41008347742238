/* eslint-disable @typescript-eslint/camelcase */
import Axios from 'axios';

interface GrantCodeBody {
  client_id: string;
  redirect_uri: 'http://localhost' | string;
}

interface GrantCodeResponse {
  redirect_uri: string;
}

const getGrantCode = (client_id: string) => {
  const body: GrantCodeBody = {
    client_id,
    redirect_uri: 'http://localhost'
  };
  // retorna uma URL em plain text contendo o grant code no query parameter code da URL retornada
  return Axios.post<GrantCodeResponse>(
    'https://api-athenasaude.sensedia.com/sandbox/sandbox/oauth/grant-code',
    body
  );
  /* Here is a snippet on how to get the query parameter code inside the returned url
    const code = new URL(axiosResponse.data).searchParams.get('code');    
  */
};

export default getGrantCode;
