import React from 'react';
import './DadosPaciente.scss';
import { AppState } from '../../configureStore';
import { connect } from 'react-redux';
import { parseDate, getIdade, parseDatetime } from '../../utils/date';
import dadosNaoEncontrados from '../../assets/icons/dados nao encontrados.png';

interface Props {
  nome: string;
  itemAbrev: string;
  dataItem: Date;
  dataNascimento: Date;
  telefone: string;
  showTelefone: boolean;
  showConsulta: boolean;
  showTitle?: boolean;
  notFound?: boolean;
}

const DadosPaciente: React.FC<Props> = (props) =>
  props.nome || props.notFound ? (
    <div className='DadosPaciente'>
      {props.showTitle ? <h3>Dados do paciente</h3> : ''}
      {props.notFound ? (
        <div className='not-found'>
          <img src={dadosNaoEncontrados} alt='Dados nao encontrados' />
          <h6>Dados não encontrados</h6>
        </div>
      ) : (
        [
          <div key={1} className='first-line'>
            <span>{props.nome}</span>
            {props.showConsulta ? (
              <span>
                {props.dataNascimento ? parseDate(props.dataNascimento) : ''}
              </span>
            ) : (
              <span>
                {props.dataNascimento
                  ? `${getIdade(props.dataNascimento)} anos`
                  : ''}
              </span>
            )}
          </div>,
          <div key={2} className='additional-line'>
            {props.showConsulta ? (
              <span>{props.itemAbrev}</span>
            ) : (
              <span>Nascimento</span>
            )}
            {props.showConsulta ? (
              <span>{props.dataItem ? parseDatetime(props.dataItem) : ''}</span>
            ) : (
              <span>{parseDate(props.dataNascimento)}</span>
            )}
          </div>,
          props.showTelefone ? (
            <div key={3} className='additional-line'>
              <span>Telefone</span>
              {props.telefone ? (
                <span>
                  <span id='ddd'>{props.telefone.slice(0, 2)} </span>
                  <span>{`${props.telefone.slice(2, 7)} ${props.telefone.slice(
                    7
                  )}`}</span>
                </span>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )
        ]
      )}
    </div>
  ) : null;

const mapStateToProps = ({ paciente }: AppState) => ({
  nome: paciente.nome,
  itemAbrev: paciente?.consulta?.[0]?.itemAbrev,
  dataItem: paciente?.consulta?.[0]?.data,
  telefone: String(paciente.telefone),
  dataNascimento: paciente.dataNascimento,
  notFound: paciente.notFound
});

export default connect(mapStateToProps)(DadosPaciente);
