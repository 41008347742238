/* eslint-disable @typescript-eslint/camelcase */
import SystemActionTypes from './system.action-types';
import { Reducer } from '../../utils/reduxTypes';

export enum LoadingState {
  Loading = 0,
  Success,
  Failed
}

export enum Logo {
  Nenhum = 0,
  SAMP
}

export interface ModalWarning {
  title: string;
  text: string;
  type: 'error' | 'warning' | 'success';
}

export interface SystemState {
  loading: LoadingState;
  warning?: ModalWarning;
  showFilas: boolean;
  tipoAtendimento: number;
  consultorio: number;
  logo: Logo;
  access_token: string;
  refresh_token: string;
  client_secret: string;
  client_id: string;
  grant_code: string;
  grant_type: 'authorization_code' | 'refresh_token';
  ddr: string;
  filial: string;
  expires_in: number;
  authorization_bs64: string;
}

const initialState: SystemState = {
  loading: LoadingState.Success,
  warning: null,
  showFilas: false,
  tipoAtendimento: null,
  consultorio: 73960,
  logo: Logo.Nenhum,
  ddr: '2733349015',
  filial: '02003001',
  access_token: null,
  client_id: '978679d0-457d-3cb2-bb72-f22fee83c8e6',
  grant_type: 'authorization_code',
  authorization_bs64:
    'Basic OTc4Njc5ZDAtNDU3ZC0zY2IyLWJiNzItZjIyZmVlODNjOGU2OjcwMTYxNDA0LTc5N2YtMzEwYi1iMGNiLTg5ZjJiNmMzMzk4OA==',
  client_secret: '70161404-797f-310b-b0cb-89f2b6c33988',
  refresh_token: null,
  grant_code: null,
  expires_in: null
};

const reducer: Reducer<SystemState, SystemActionTypes> = (
  state = { ...initialState },
  action: { type: SystemActionTypes; payload: Partial<SystemState> }
): SystemState => {
  switch (action.type) {
    case SystemActionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      };
    case SystemActionTypes.CLEAR_LOADING:
      return {
        ...state,
        loading: initialState.loading
      };
    case SystemActionTypes.UPDATE_WARNING:
      return {
        ...state,
        warning: action.payload.warning
      };
    case SystemActionTypes.UPDATE_LOGO:
      return {
        ...state,
        logo: action.payload.logo
      };
    case SystemActionTypes.CLEAR_WARNING:
      return {
        ...state,
        warning: initialState.warning
      };
    case SystemActionTypes.CLEAR_ATENDIMENTO:
      return {
        ...state,
        tipoAtendimento: null
      };
    case SystemActionTypes.UPDATE_ATENDIMENTO:
      return {
        ...state,
        tipoAtendimento: action.payload.tipoAtendimento
      };
    case SystemActionTypes.UPDATE_CONSULTORIO:
      return {
        ...state,
        consultorio: action.payload.consultorio
      };
    case SystemActionTypes.UPDATE_AUTH:
      return {
        ...state,
        ...action.payload
      };
    case SystemActionTypes.UPDATE_FILAS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default reducer;
