import React, { useState, ReactElement, useRef, useContext } from 'react';
import './ModalSMS.scss';
import ModalContainer from '../ModalContainer/ModalContainer';
import sms from '../../../assets/icons/sms.png';
import token from '../../../assets/icons/smstoken.png';
import Input from '../../Input/Input';
import {
  LoadingState,
  updateLoading,
  ModalWarning,
  updateWarning,
  SystemState
} from '../../../stores/system';
import Axios from 'axios';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../../environment';
import { AppState } from '../../../configureStore';
import PacienteActionTypes from '../../../stores/paciente/paciente.action-types';
import { updatePaciente, PacienteState } from '../../../stores/paciente';
import SystemActionTypes from '../../../stores/system/system.action-types';
import { connect } from 'react-redux';
import { LinkContext } from '../../../routes';
import {
  HttpBiometricResponse,
  IdentificacaoHttp
} from '../../../utils/httpTypes';

interface HttpTokenBody {
  matricula: string;
  digital: string;
}

interface HttpTokenBody2 {
  codigoPrestador: number;
  numeroCarteirinha: string;
}

interface Props {
  system: SystemState;
  show: boolean;
  closeModal: () => any;
  paciente: PacienteState;
  bioData: HttpBiometricResponse;
  updatePaciente: typeof updatePaciente;
  setLoadingStatus(status: LoadingState);
  updateWarning(warning: ModalWarning);
}

const ModalSMS: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);
  const [checkToken, setCheckToken] = useState(false);
  const inputRef: React.MutableRefObject<Input> = useRef(null);
  const sendToken = async () => {
    try {
      props.setLoadingStatus(LoadingState.Loading);
      // if (!props.bioData?.template?.buffer) {
      //   props.updateWarning({
      //     text: 'Erro ao obter a digital. Entre em contato com o suporte',
      //     title: 'Erro',
      //     type: 'error'
      //   });
      //   props.setLoadingStatus(LoadingState.Failed);
      //   return;
      // }
      const body: HttpTokenBody2 = {
        // digital: props.bioData.template.buffer, // oq é isso?
        // digital: 'douglas', // oq é isso?
        codigoPrestador: 9919022,
        numeroCarteirinha: [
          props.paciente.convenio.slice(0, 3),
          props.paciente.convenio.slice(3)
        ].join('.')
      };
      await Axios.post(environment.tokenService, body, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          access_token: props.system.access_token,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id: props.system.client_id,
          filial: '02003001'
        }
      });
    } catch (e) {
      props.updateWarning({
        text: environment.modalToken,
        title: 'Erro',
        type: 'error'
      });
      props.setLoadingStatus(LoadingState.Failed);
      return;
    }
    props.setLoadingStatus(LoadingState.Success);
    setCheckToken(true);
  };
  const validateToken = async () => {
    // valida o token que o cara inseriu
    try {
      props.setLoadingStatus(LoadingState.Loading);      
      const response = await Axios.get(environment.tokenService, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          access_token: props.system.access_token,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id: props.system.client_id,
          filial: '02003001'
        },
        params: {
          token: inputRef.current.inputElRef.current.value?.toUpperCase(),
          carteirinha: [
            props.paciente.convenio.slice(0, 3),
            props.paciente.convenio.slice(3)
          ].join('.')
        }
      });
      if (!(response.status >= 200 && response.status < 400)) {
        throw new Error('Erro');
      }
      props.setLoadingStatus(LoadingState.Success);
      props.closeModal();
      context.Agendamentos.current.click();
    } catch (e) {
      props.updateWarning({
        text: 'Erro ao validar token. Entre em contato com o suporte',
        title: 'Erro',
        type: 'error'
      });
      props.setLoadingStatus(LoadingState.Failed);
      return;
    }
  };

  const returnValue = ([
    <ModalContainer
      key={1}
      showConsulta={false}
      showTelefone={false}
      icon={sms}
      iconAlt='SMS'
      title='Confirmação'
      show={props.show && !checkToken}
      desc='Você receberá o TOKEN via SMS em seu telefone!'
      close={props.closeModal}
      leftBtn={{
        func: props.closeModal,
        title: 'Cancelar'
      }}
      rightBtn={{
        func: sendToken,
        title: 'Confirmar'
      }}></ModalContainer>,
    <ModalContainer
      key={2}
      hideDados={true}
      showConsulta={false}
      showTelefone={false}
      icon={token}
      iconAlt='Token'
      title='Confirmação'
      show={props.show && checkToken}
      desc='Digite o token recebido no seu telefone'
      close={() => {
        props.closeModal();
        setCheckToken(false);
      }}
      leftBtn={{
        func: () => {
          props.closeModal();
          setCheckToken(false);
        },
        title: 'Cancelar'
      }}
      rightBtn={{
        func: validateToken,
        title: 'Confirmar'
      }}>
      <Input
        ref={inputRef}
        type='number'
        required={true}
        placeholder='Toque aqui para digitar'
      />
    </ModalContainer>
  ] as unknown) as ReactElement<any, any>;

  return returnValue;
};

const mapStateToProps = ({ paciente, system }: AppState) => ({
  paciente,
  system
});

const mapDispatchToProps = (dispatch) => ({
  updatePaciente: (
    type: PacienteActionTypes,
    payload: IdentificacaoHttp | PacienteState
  ) => dispatch(updatePaciente(type as PacienteActionTypes.LOADED, payload)),
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSMS);
