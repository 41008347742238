import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  ReactElement
} from 'react';
import './ModalReconFacial.scss';
import {
  LoadingState,
  ModalWarning,
  updateLoading,
  updateWarning
} from '../../../stores/system';
import reconFacial from '../../../assets/imgs/reconhecimento facial.jpg';
import ModalContainer from '../ModalContainer/ModalContainer';
import SystemActionTypes from '../../../stores/system/system.action-types';
import { connect } from 'react-redux';
import { LinkContext } from '../../../routes';
import { AxiosResponse } from 'axios';
import { environment } from '../../../environment';
import axios from 'axios';
import ModalSMS from '../ModalSMS/ModalSMS';

interface Props {
  closeModal(): any;
  show: boolean;
  setLoadingStatus(status: LoadingState);
  updateWarning(warning: ModalWarning);
}

const ModalReconFacial: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);
  const picValue = useRef(false);
  const [sms, setSms] = useState(false);
  const [validPic, setValidPic] = useState(false);
  const validatePic = useCallback(async () => {
    if (!validPic) {
      props.setLoadingStatus(LoadingState.Loading);
      let response: AxiosResponse<any[]>;
      try {
        response = await axios.get(
          `${environment.baseUrl}/pic?value=${picValue.current}`,
          {
            headers: {
              Authorization: 'Basic YWRtaW46bWFzdGVy'
            }
          }
        );
      } catch (e) {
        props.updateWarning({
          text: 'Erro ao autenticar. Entre em contato com o suporte',
          title: 'Erro',
          type: 'error'
        });
        props.setLoadingStatus(LoadingState.Failed);
        return;
      }
      props.setLoadingStatus(LoadingState.Success);
      picValue.current = !picValue.current;
      if (response.data && response.data.length) {
        props.updateWarning({
          text: 'Rosto autenticado com sucesso',
          title: 'Autenticação',
          type: 'success'
        });
        setValidPic(true);
      } else {
        props.updateWarning({
          text: 'Rosto não corresponde com cadastro',
          title: 'Autenticação',
          type: 'warning'
        });
      }
    }
  }, [validPic, props]);

  const returnValue = ([
    <ModalContainer
      key={1}
      showConsulta={false}
      showTelefone={false}
      onImgClick={validatePic}
      icon={reconFacial}
      iconAlt='Biometria'
      title='Confirmação'
      show={props.show && !sms}
      desc='Posicione corretamente o seu rosto em frente a câmera'
      close={props.closeModal}
      leftBtn={{
        func: props.closeModal,
        title: 'Cancelar'
      }}
      rightBtn={
        validPic
          ? {
              func: () => context.Agendamentos.current.click(),
              title: 'Continuar'
            }
          : {
              func: () => setSms(true),
              title: 'Tentar por SMS'
            }
      }
    />,
    <ModalSMS
      bioData={null}
      key={2}
      show={sms && props.show}
      closeModal={() => {
        setSms(false);
        props.closeModal();
      }}
    />
  ] as unknown) as ReactElement<any, any>;

  return returnValue;
};

const mapDispatchToProps = (dispatch) => ({
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

export default connect(null, mapDispatchToProps)(ModalReconFacial);
