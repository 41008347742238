import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ModalSingleInput.scss';
import ModalContainer from '../ModalContainer/ModalContainer';
import Input from '../../Input/Input';
import { connect } from 'react-redux';
import {
  updatePaciente,
  PacienteState,
  updateBio
} from '../../../stores/paciente';
import PacienteActionTypes from '../../../stores/paciente/paciente.action-types';
import { AppState } from '../../../configureStore';
import axios, { AxiosResponse } from 'axios';
import { HttpAuthResponse, IdentificacaoHttp } from '../../../utils/httpTypes';
import {
  LoadingState,
  ModalWarning
} from '../../../stores/system/system.store';
import { updateLoading, updateWarning } from '../../../stores/system';
import SystemActionTypes from '../../../stores/system/system.action-types';
import * as qrscanner from '@nstseek/qrscanner';
import { environment } from '../../../environment';

interface Props {
  closeModal(): any;
  updatePaciente: typeof updatePaciente;
  setLoadingStatus(status: LoadingState);
  updateWarning(warning: ModalWarning);
  updateBio(payload: Required<Pick<PacienteState, 'biometricData'>>);
  showConsulta?: boolean;
  show: boolean;
  paciente: PacienteState;
  title: string;
  desc: string;
  icon: string;
  iconAlt: string;
  route: string;
  mask?: string;
  maxlength?: number;
  validity?: ((inputEl: HTMLInputElement) => boolean)[];
  redirect?: () => any;
  qrscan?: boolean;
  type?: 'number' | 'text' | 'date';
  qr?: boolean;
}

const ModalSingleInput: React.FC<Props> = (props) => {
  const inputRef = useRef<Input>();
  const [video, setVideo] = useState(props.qrscan);
  const scanner = useRef(null);

  const confirmar = async () => {
    const inputEl = inputRef.current.inputElRef.current;
    if (props.validity) {
      for (const validityFunc of props.validity) {
        if (!validityFunc(inputEl)) {
          return;
        }
      }
    }
    const inputData = inputRef.current.inputElRef.current.value.replace(
      /\D/g,
      ''
    );
    props.setLoadingStatus(LoadingState.Loading);
    let response: AxiosResponse<IdentificacaoHttp>;
    let bioData: AxiosResponse<HttpAuthResponse | HttpAuthResponse[]>;
    try {
      response = await axios.get(`${props.route}=${inputData}`, {
        headers: {
          Authorization: 'Basic YWRtaW46bWFzdGVy'
        }
      });
      bioData = await axios.get(`${environment.biometricDatabase}`, {
        params: {
          empresa: '02003001',
          matricula: [
            response.data.dados.cartao.slice(0, 3),
            response.data.dados.cartao.slice(3)
          ].join('.')
        }
      });
    } catch (e) {
      props.updateWarning({
        text: environment.consultaPaciente,
        title: 'Erro',
        type: 'error'
      });
      props.setLoadingStatus(LoadingState.Success);
      return;
    }
    props.setLoadingStatus(LoadingState.Success);
    // if (
    //   response.data.dados.usar_biometria &&
    //   (!bioData.data || (Array.isArray(bioData.data) && !bioData.data.length))
    // ) {
    //   // props.updateWarning({
    //   //   text: 'Biometria não cadastrada.',
    //   //   title: 'Erro',
    //   //   type: 'error'
    //   // });
    //   // props.updatePaciente(PacienteActionTypes.LOADED, {
    //   //   ...props.paciente,
    //   //   notFound: false,
    //   //   authQr: props.qr
    //   // });
    //   props.setLoadingStatus(LoadingState.Success);
    //   return;
    // }
    if (
      !response.data ||
      !response.data.dados ||
      !response.data.dados.consultas?.length
    ) {
      props.updateWarning({
        text: 'Paciente não encontrado',
        title: 'Alerta',
        type: 'warning'
      });
      props.updatePaciente(PacienteActionTypes.LOADED, {
        ...props.paciente,
        notFound: false,
        authQr: props.qr
      });
      props.setLoadingStatus(LoadingState.Success);
      return;
    }
    props.updatePaciente(PacienteActionTypes.LOADED, {
      ...response.data,
      notFound: false,
      authQr: props.qr
    });
    if (bioData.data) {
      props.updateBio({ biometricData: bioData.data });
    }
    props.setLoadingStatus(LoadingState.Success);
  };

  const rightBtnFn = props.paciente.nome ? () => props.redirect() : confirmar;

  const qrScanFn = useCallback(() => {
    scanner.current = new qrscanner.Scanner({
      video: document.getElementById('preview')
    });
    scanner.current.addListener('scan', (content) => {
      inputRef.current.inputElRef.current.value = content;
      rightBtnFn();
    });
    qrscanner.Camera.getCameras().then((cameras) => {
      if (cameras.length > 0) {
        scanner.current.start(cameras[0]);
      }
    });
  }, [scanner, rightBtnFn]);
  useEffect(() => {
    return () => {
      if (scanner.current) {
        scanner.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (
      props.show &&
      !scanner.current &&
      document.getElementById('preview') &&
      !props.paciente.nome
    ) {
      qrScanFn();
    }
  }, [props.show, props.paciente.nome, qrScanFn]);

  useEffect(() => {
    if (props.qrscan) {
      if (props.paciente.nome) {
        setVideo(false);
      } else {
        setVideo(true);
      }
    }
  }, [props.qrscan, props.paciente.nome]);

  return (
    <ModalContainer
      video={video}
      showConsulta={!!props.showConsulta}
      showTelefone={false}
      icon={props.icon}
      iconAlt={props.iconAlt}
      desc={props.desc}
      title={props.title}
      show={props.show}
      close={() => {
        props.updatePaciente(PacienteActionTypes.CLEAR);
        props.closeModal();
      }}
      leftBtn={{
        func: () => {
          props.updatePaciente(PacienteActionTypes.CLEAR);
          props.closeModal();
        },
        title: 'Cancelar'
      }}
      rightBtn={{
        func: rightBtnFn,
        title: props.paciente.nome ? 'Confirmar' : 'Consultar'
      }}>
      <Input
        type={props.type ? props.type : 'text'}
        required={true}
        placeholder='Toque aqui para digitar'
        ref={(el) => (inputRef.current = el)}
        onChange={() => {
          if (
            props.maxlength &&
            inputRef.current.inputElRef.current.value.length === props.maxlength
          ) {
            inputRef.current.inputElRef.current.blur();
          }
          return props.paciente.nome || props.paciente.notFound
            ? props.updatePaciente(PacienteActionTypes.CLEAR)
            : null;
        }}
        keydown={(event) => (event.key === 'Enter' ? confirmar() : null)}
      />
    </ModalContainer>
  );
};

const mapStateToProps = ({ paciente }: AppState) => ({
  paciente
});

const mapDispatchToProps = (dispatch) => ({
  updatePaciente: (
    type: PacienteActionTypes,
    payload: IdentificacaoHttp | PacienteState
  ) => dispatch(updatePaciente(type as PacienteActionTypes.LOADED, payload)),
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning })),
  updateBio: (payload: Required<Pick<PacienteState, 'biometricData'>>) =>
    dispatch(updateBio(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSingleInput);
