import React from 'react';
import './RosaDiagonal.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import rosaDiagonal from '../../assets/icons/rosa diagonal.png';
import { Routes } from '../../routes';

class RosaDiagonal extends React.Component<RouteComponentProps> {
  render() {
    return this.props.location.pathname !== Routes.Inicio ? (
      <img src={rosaDiagonal} alt='Rosa diagonal' id='rosa-diagonal' />
    ) : (
      ''
    );
  }

  shouldComponentUpdate(nextProps: RouteComponentProps) {
    return nextProps.location.pathname !== this.props.location.pathname;
  }
}

export default withRouter(RosaDiagonal);
