import React from 'react';
import './Consulta.scss';
import { ConsultaParsed } from '../../stores/paciente/paciente.store';
import { parseDate, parseTime } from '../../utils/date';
import vertex from '../../assets/icons/vertex border.png';

interface Props {
  consulta: ConsultaParsed & { id: number };
  selected: boolean;
  setSelected: (consultaId: number) => any;
}

const Consulta: React.FC<Props> = (props) => (
  <div
    className={`${props.selected ? 'selected ' : ''}Consulta${
      props.consulta.critica ? ' disabled' : ''
    }`}
    onClick={() =>
      props.consulta.critica ? () => null : props.setSelected(props.consulta.id)
    }>
    <img src={vertex} alt='Vertex border' id='upper-left' />
    <img src={vertex} alt='Vertex border' id='lower-right' />
    <div className='datetime'>
      <span className='title'>Data:</span>
      <span className='content'>{parseDate(props.consulta.data)}</span>
      <span className='title'>Horário:</span>
      <span className='content'>{parseTime(props.consulta.data)}</span>
    </div>
    <div className='dados'>
      <span className='content'>
        <span className='title'>Item:</span> {props.consulta.item}
      </span>
      <span className='content'>
        <span className='title'>Médico:</span> {props.consulta.medico}
      </span>
      {props.consulta.critica ? (
        <span className='content'>
          <span className='title'>Crítica:</span> {props.consulta.critica}
        </span>
      ) : (
        ''
      )}
    </div>
  </div>
);

export default Consulta;
