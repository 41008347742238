import React, { useEffect, useContext, useRef } from 'react';
import './ConsultaConfirmada.scss';
import { ConsultaParsed, PacienteState, updatePaciente } from '../../stores/paciente';
import { parseDate, parseTime } from '../../utils/date';
import { AppState } from '../../configureStore';
import { connect } from 'react-redux';
import { LinkContext } from '../../routes';
import PacienteActionTypes from '../../stores/paciente/paciente.action-types';
import { updateAtendimento } from '../../stores/system';
import SystemActionTypes from '../../stores/system/system.action-types';

interface Props {
  paciente: PacienteState;
  consulta?: ConsultaParsed;
  pendencia?: boolean;
  sala?: string;
  senha: string | number;
  clearPaciente: () => any;
  clearAtendimento: () => any;
}

const redirectTimeout = 10000;

const ConsultaConfirmada: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);
  const timeoutAction = useRef(null);
  useEffect(() => {
    if (!timeoutAction.current) {
      timeoutAction.current = setTimeout(() => {
        props.clearPaciente();
        props.clearAtendimento();
        context.Inicio.current.click();
      }, redirectTimeout);
    }
    if (props.senha) {
      const ifr = document.createElement('iframe');
      ifr.style.display = 'none';
      document.body.appendChild(ifr);
      const printWin = ifr.contentWindow;
      printWin.document.body.innerHTML = `
      ${props.paciente.nome ? `<h3>Senha: ${props.senha}</h3>` : ''}
      ${
        props.consulta
          ? `<h4>Consulta: ${props.consulta.especialidade} - ${
              props.consulta.item
            } - Dr(a). ${
              props.consulta.medico
            } - ${props.consulta.data.toLocaleString()}</h4>`
          : ''
      }
    ${props.consulta ? '' : `<h1>Senha: ${props.senha}</h1>`}
      ${props.sala ? `<h4>Sala: ${props.sala}</h4>` : ''}`;
      printWin.addEventListener('afterprint', () =>
        document.body.removeChild(ifr)
      );
      printWin.print();
      window.focus();
    } // eslint-disable-next-line
  }, [props.senha]);

  return (
    <div className='ConsultaConfirmada'>
      {props.consulta ? (
        <div className='consulta'>
          <div className='datetime'>
            <span className='title'>Data:</span>
            <span className='content'>{parseDate(props.consulta.data)}</span>
            <span className='title'>Horário:</span>
            <span className='content'>{parseTime(props.consulta.data)}</span>
          </div>
          <div className='dados'>
            <span className='content'>
              <span className='title'>Item:</span> {props.consulta.item}
            </span>
            <span className='content'>
              <span className='title'>Médico:</span> {props.consulta.medico}
            </span>
            <span className='content'>
              <span className='title'>Sala:</span>{' '}
              {props.paciente.sala ? props.paciente.sala : props.sala}
            </span>
          </div>
        </div>
      ) : null}
      <div className={`atendimento${props.pendencia ? ' pendencia' : ''}`}>
        <span>
          {props.pendencia
            ? 'Há pendência para o atendimento, aguarde ser chamado pela senha'
            : 'Check-in realizado com sucesso'}
        </span>
        <div>Senha: {props.senha}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ paciente }: AppState) => ({
  paciente
});

const mapDispatchToProps = (dispatch) => ({
  clearPaciente: () => dispatch(updatePaciente(PacienteActionTypes.CLEAR)),
  clearAtendimento: () =>
    dispatch(updateAtendimento(SystemActionTypes.CLEAR_ATENDIMENTO))
});

export default connect(mapStateToProps,mapDispatchToProps)(ConsultaConfirmada);
