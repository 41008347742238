import React, { useState, useEffect, useContext } from 'react';
import './Atendimento.scss';
import PageContainer from '../../components/PageContainer/PageContainer';
import {
  LoadingState,
  updateLoading,
  updateAtendimento,
  ModalWarning,
  updateWarning
} from '../../stores/system';
import SystemActionTypes from '../../stores/system/system.action-types';
import { connect } from 'react-redux';
import Axios, { AxiosResponse } from 'axios';
import { AtendimentoHttp } from '../../utils/httpTypes';
import Button from '../../components/Button/Button';
import { LinkContext } from '../../routes';
import _ from 'lodash';
import { AppState } from '../../configureStore';
import { environment } from '../../environment';
import normal from '../../assets/icons/Icone atendimento normal.png';
import prioritario from '../../assets/icons/Icone atendimento prioritario.png';

interface Props {
  setLoadingStatus: (state: LoadingState) => any;
  updateAtendimento: (id: number) => any;
  updateWarning: (warning: ModalWarning) => any;
  consultorio: number;
}

const Atendimento: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);
  const [atendimentos, setAtendimentos] = useState<AtendimentoHttp>(null);

  useEffect(() => {
    const asyncFunc = async () => {
      props.setLoadingStatus(LoadingState.Loading);
      let response: AxiosResponse<AtendimentoHttp>;
      try {
        response = await Axios.get(
          `${environment.proxy}/wsapichecking/services/filas-atendimento?consultorio=${props.consultorio}`,
          {
            headers: {
              Authorization: 'Basic YWRtaW46bWFzdGVy'
            }
          }
        );
      } catch (e) {
        props.updateWarning({
          text: 'Erro ao consulta atendimentos. Entre em contato com o suporte',
          title: 'Erro',
          type: 'error'
        });
        props.setLoadingStatus(LoadingState.Failed);
        return;
      }
      props.setLoadingStatus(LoadingState.Success);
      setAtendimentos(response.data);
    };
    asyncFunc();
  }, [props]);

  const buttons = atendimentos?.dados?.map((atendimento, index) => (
    <Button
      key={index}
      big={true}
      icon={index === 0 ? normal : prioritario}
      iconAlt='Icone atendimento'
      func={() => {
        props.updateAtendimento(atendimento.id);
        context.Filas.current.click();
      }}>
      {_.capitalize(atendimento.nome)}
    </Button>
  ));

  return (
    <PageContainer title='Tipo de atendimento' breadcrumbState={1}>
      <div className='Atendimento'>
        <h3>Olá! Seja Bem-vindo!</h3>
        <h5>
          Para encaminhar seu atendimento, selecione uma das opções abaixo:
        </h5>
        {buttons}
      </div>
    </PageContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLoadingStatus: (state: LoadingState) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: state })
    ),
  updateAtendimento: (id: number) =>
    dispatch(
      updateAtendimento(SystemActionTypes.UPDATE_ATENDIMENTO, {
        tipoAtendimento: id
      })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

const mapStateToProps = ({ system }: AppState) => ({
  consultorio: system.consultorio
});

export default connect(mapStateToProps, mapDispatchToProps)(Atendimento);
