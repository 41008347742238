import Axios from 'axios';
import { environment } from './environment';

const axiosHook = Axios.create();

export const triggerHook = (content: string) => {
  // axiosHook.post(environment.webhook, {
  //   username: 'Athenão',
  //   content: content.slice(0, 2000)
  // });
};
