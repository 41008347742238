import React, { useContext } from 'react';
import logo from '../../assets/icons/logo colorido.png';
import breadcrumb1 from '../../assets/imgs/timeline1.png';
import breadcrumb2 from '../../assets/imgs/timeline2.png';
import breadcrumb3 from '../../assets/imgs/timeline3.png';
import './PageContainer.scss';
import Button from '../Button/Button';
import { LinkContext } from '../../routes';
import { connect } from 'react-redux';
import { updatePaciente } from '../../stores/paciente';
import PacienteActionTypes from '../../stores/paciente/paciente.action-types';
import { updateAtendimento, Logo } from '../../stores/system';
import SystemActionTypes from '../../stores/system/system.action-types';
import { AppState } from '../../configureStore';

interface Props {
  title: string;
  breadcrumbState: 1 | 2 | 3;
  children?: any;
  logo: Logo;
  clearPaciente: () => any;
  clearAtendimento: () => any;
}

const PageContainer: React.FC<Props> = (props) => {
  const context = useContext(LinkContext);
  const getBreadcrumb = (state: 1 | 2 | 3) => {
    switch (state) {
      case 1:
        return breadcrumb1;
      case 2:
        return breadcrumb2;
      case 3:
        return breadcrumb3;
    }
  };
  return (
    <div className='PageContainer'>
      {props.logo === Logo.SAMP ? <img src={logo} alt='Logo' id='logo' /> : ''}
      <div id='header'>
        <h3>{props.title}</h3>
        <img
          src={getBreadcrumb(props.breadcrumbState)}
          alt={`Breadcrumb state ${props.breadcrumbState}`}
        />
      </div>
      <div id='content'>{props.children}</div>
      <div id='footer'>
        <h4>DICAS:</h4>
        <h6>
          Toque na tela para escolher,
          <br />
          ou comece novamente na tela inicial.
        </h6>
        <Button
          id='back'
          func={() => {
            props.clearPaciente();
            props.clearAtendimento();
            context.Inicio.current.click();
          }}>
          Voltar a tela inicial
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ system }: AppState) => ({
  logo: system.logo
});

const mapDispatchToProps = (dispatch) => ({
  clearPaciente: () => dispatch(updatePaciente(PacienteActionTypes.CLEAR)),
  clearAtendimento: () =>
    dispatch(updateAtendimento(SystemActionTypes.CLEAR_ATENDIMENTO))
});

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
