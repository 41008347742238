enum SystemActionTypes {
  UPDATE_LOADING = 'system/UPDATE_LOADING',
  UPDATE_WARNING = 'system/UPDATE_WARNING',
  UPDATE_ATENDIMENTO = 'system/UPDATE_ATENDIMENTO',
  UPDATE_CONSULTORIO = 'system/UPDATE_CONSULTORIO',
  UPDATE_LOGO = 'system/UPDATE_LOGO',
  CLEAR_WARNING = 'system/CLEAR_WARNING',
  CLEAR_LOADING = 'system/CLEAR_LOADING',
  CLEAR_ATENDIMENTO = 'system/CLEAR_ATENDIMENTO',
  CLEAR_CONSULTORIO = 'system/CLEAR_CONSULTORIO',
  UPDATE_AUTH = 'system/UPDATE_AUTH',
  UPDATE_FILAS = 'system/UPDATE_FILAS'
}

export default SystemActionTypes;
