import React from 'react';
import './ModalContainer.scss';
import Backdrop from '../../Backdrop/Backdrop';
import Button from '../../Button/Button';
import DadosPaciente from '../../DadosPaciente/DadosPaciente';

interface Button {
  func: () => any;
  title: string;
}

interface Props {
  title: string;
  icon?: string;
  iconAlt?: string;
  desc?: string;
  leftBtn?: Button;
  rightBtn?: Button;
  show: boolean;
  showConsulta: boolean;
  showTelefone: boolean;
  hideDadosTitle?: boolean;
  hideDados?: boolean;
  video?: boolean;
  onImgClick?: () => any;
  close: () => any;
}

const ModalContainer: React.FC<Props> = (props) => {
  return (
    <Backdrop show={props.show}>
      <div className='modal-container'>
        <span onClick={props.close}>X</span>
        <h3>{props.title}</h3>
        {props.video ? (
          ''
        ) : (
          <img
            src={props.icon}
            alt={props.iconAlt}
            onClick={props.onImgClick ? props.onImgClick : null}
          />
        )}
        {props.video ? <video id='preview'></video> : ''}
        {props.desc ? <h6>{props.desc}</h6> : ''}
        <div className='content'>{props.children}</div>
        {!props.hideDados && !props.video ? (
          <DadosPaciente
            showConsulta={props.showConsulta}
            showTelefone={props.showTelefone}
            showTitle={!props.hideDadosTitle}
          />
        ) : null}
        <div className='buttons'>
          {props.leftBtn ? (
            <Button func={props.leftBtn.func} cssh3Class={'modal-btn'}>
              {props.leftBtn.title}
            </Button>
          ) : null}
          {props.rightBtn ? (
            <Button func={props.rightBtn.func} cssh3Class={'modal-btn'}>
              {props.rightBtn.title}
            </Button>
          ) : null}
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalContainer;
