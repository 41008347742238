import React from 'react';
import './ModalNomeDataNascimento.scss';
import ModalContainer from '../ModalContainer/ModalContainer';
import Input from '../../Input/Input';
import nomeDataNasc from '../../../assets/icons/nome e data de nascimento blue.png';
import { AppState } from '../../../configureStore';
import PacienteActionTypes from '../../../stores/paciente/paciente.action-types';
import { updatePaciente, PacienteState } from '../../../stores/paciente';
import {
  LoadingState,
  updateLoading,
  ModalWarning,
  updateWarning
} from '../../../stores/system';
import SystemActionTypes from '../../../stores/system/system.action-types';
import { connect } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { environment } from '../../../environment';
import { IdentificacaoHttp } from '../../../utils/httpTypes';
import { parseDate, removeTimezone } from '../../../utils/date';

interface Props {
  closeModal(): any;
  show: boolean;
  paciente: PacienteState;
  updatePaciente: typeof updatePaciente;
  setLoadingStatus(status: LoadingState);
  updateWarning(warning: ModalWarning);
  setAuthMode: () => any;
  consultorio: number;
}

const ModalNomeDataNascimento: React.FC<Props> = (props) => {
  const inputNomeRef = React.createRef<Input>();
  const inputDataRef = React.createRef<Input>();

  return (
    <ModalContainer
      showConsulta={false}
      showTelefone={false}
      icon={nomeDataNasc}
      iconAlt='Nome e data de nascimento'
      title='Confirmação'
      show={props.show}
      hideDadosTitle={!props.paciente.nome}
      close={() => {
        props.updatePaciente(PacienteActionTypes.CLEAR);
        props.closeModal();
      }}
      leftBtn={{
        func: () => {
          props.updatePaciente(PacienteActionTypes.CLEAR);
          props.closeModal();
        },
        title: 'Cancelar'
      }}
      rightBtn={{
        func: props.paciente.nome
          ? props.setAuthMode
          : async () => {
              const inputNome = inputNomeRef.current.inputElRef.current;
              const inputData = inputDataRef.current.inputElRef.current;
              if (!inputNome.value) {
                inputNome.setCustomValidity('Esse campo é obrigatório');
                inputNome.reportValidity();
                return;
              }
              if (!inputData.value) {
                inputData.setCustomValidity('Esse campo é obrigatório');
                inputData.reportValidity();
                return;
              }
              props.setLoadingStatus(LoadingState.Loading);
              const date = removeTimezone(new Date(inputData.value));
              let response: AxiosResponse<IdentificacaoHttp>;
              try {
                response = await axios.get(
                  `${
                    environment.proxy
                  }/wsapichecking/services/filtro-agendamento?consultorio=${
                    props.consultorio
                  }&nome=${inputNome.value}&dt_nascimento=${parseDate(date)}`,
                  {
                    headers: {
                      Authorization: 'Basic YWRtaW46bWFzdGVy'
                    }
                  }
                );
              } catch (e) {
                props.updateWarning({
                  text:
                    'Erro ao consultar pacientes. Entre em contato com o suporte',
                  title: 'Erro',
                  type: 'error'
                });
                props.setLoadingStatus(LoadingState.Failed);
                return;
              }
              if (
                !response.data ||
                !response.data.dados ||
                !response.data.dados.consultas?.length
              ) {
                props.updateWarning({
                  text: 'Paciente não encontrado',
                  title: 'Alerta',
                  type: 'warning'
                });
                props.updatePaciente(PacienteActionTypes.LOADED, {
                  ...props.paciente,
                  notFound: true
                });
                props.setLoadingStatus(LoadingState.Success);
                return;
              }
              props.updatePaciente(PacienteActionTypes.LOADED, {
                ...response.data,
                notFound: false
              });
              props.setLoadingStatus(LoadingState.Success);
            },
        title: props.paciente.nome ? 'Confirmar' : 'Consultar'
      }}>
      <Input
        title='Nome completo'
        type='text'
        required={true}
        placeholder='Toque aqui para digitar'
        ref={inputNomeRef}
        onChange={() =>
          props.paciente.nome || props.paciente.notFound
            ? props.updatePaciente(PacienteActionTypes.CLEAR)
            : null
        }
      />
      <Input
        title='Data de nascimento'
        type='date'
        required={true}
        placeholder='Toque aqui para selecionar'
        ref={inputDataRef}
        onChange={() =>
          props.paciente.nome || props.paciente.notFound
            ? props.updatePaciente(PacienteActionTypes.CLEAR)
            : null
        }
      />
    </ModalContainer>
  );
};

const mapStateToProps = ({ paciente, system }: AppState) => ({
  paciente,
  consultorio: system.consultorio
});

const mapDispatchToProps = (dispatch) => ({
  updatePaciente: (
    type: PacienteActionTypes,
    payload: IdentificacaoHttp | PacienteState
  ) => dispatch(updatePaciente(type as PacienteActionTypes.LOADED, payload)),
  setLoadingStatus: (status) =>
    dispatch(
      updateLoading(SystemActionTypes.UPDATE_LOADING, { loading: status })
    ),
  updateWarning: (warning: ModalWarning) =>
    dispatch(updateWarning(SystemActionTypes.UPDATE_WARNING, { warning }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalNomeDataNascimento);
